export default [
  {
    name: 'ciasta',
    url: '',
  },
  {
    name: 'chleby',
    url: '',
  },
  {
    name: 'granola',
    url: '',
  },
  {
    name: 'desery',
    url: '',
  },
  {
    name: 'torty',
    url: '',
  },
  {
    name: 'peanutpaste',
    url: '',
  },
  {
    name: 'littlesomething',
    url: '',
  },
  {
    name: 'przetwory',
    url: '',
  },
  {
    name: 'napoje',
    url: '',
  },
  {
    name: 'inne',
    url: '',
  },
];
