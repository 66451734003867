<template>
  <v-card
    class="pa-0 ma-0"
    :elevation="6"
    :outlined="false"
  >
    <div class="d-flex ">
      <v-sheet
        :height="cardSize"
        class="d-none d-lg-flex pa-0 ma-0 flex-grow-0"
        elevation="40"
      >
        <v-img
          :src="require('../assets/' + leftImgRef)"
          lazy-src="../assets/stacja_blur.png"
          :height="imgHeight"
          :width="imgWidth"
          class="pa-0 ma-0 flex-grow-1"
        >
          <template #placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              />
            </v-row>
          </template>
        </v-img>
      </v-sheet>
      <v-sheet
        :max-height="cardSize"
        class="pa-0 ma-0 flex-grow-1"
      >
        <slot />
      </v-sheet>
      <v-sheet
        :height="cardSize"
        class="d-none d-md-flex pa-0 ma-0 flex-grow-0"
      >
        <v-img
          :src="require('../assets/' + rightImgRef)"
          lazy-src="../assets/stacja_blur.png"
          :height="imgHeight"
          :width="imgWidth"
          class="pa-0 ma-0 flex-grow-0"
        >
          <template #placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              />
            </v-row>
          </template>
        </v-img>
      </v-sheet>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'Card',
  props: {
    rightImgRef: {
      type: String,
      required: true,
    },
    leftImgRef: {
      type: String,
      required: true,
    },
  },
  data: () => ({

  }),
  computed: {
    cardSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 400;
        case 'sm': return 500;
        case 'md': return 500;
        case 'lg': return 600;
        case 'xl': return 700;
        default: return 600;
      }
    },
    imgWidth() {
      return this.cardSize * (2 / 3);
    },
    imgHeight() {
      return this.cardSize;
    },
  },
};
</script>

<style>
</style>
