<template>
  <v-app-bar
    app
    color="white"
    flat
    scroll-threshold="300"
    class="navbarBorder"
  >
    <v-spacer />
    <v-btn
      href="https://www.facebook.com/stacjazerolodzgorna"
      target="_blank"
      icon
    >
      <v-icon
        :medium="this.$vuetify.breakpoint.mobile"
        :large="!this.$vuetify.breakpoint.mobile"
      >
        mdi-facebook
      </v-icon>
    </v-btn>

    <v-btn
      href="https://www.instagram.com/stacjazero"
      target="_blank"
      icon
    >
      <v-icon
        :medium="this.$vuetify.breakpoint.mobile"
        :large="!this.$vuetify.breakpoint.mobile"
      >
        mdi-instagram
      </v-icon>
    </v-btn>
    <template
      #extension
    >
      <v-tabs
        light
        color="basil"
        :fixed-tabs="$vuetify.breakpoint.mobile"
        :grow="!$vuetify.breakpoint.mobile"
        hide-slider
      >
        <v-tab
          v-for="item in navBarTiles"
          :key="item.text"
          :ripple="false"
          class="tab pa-0"
          @click="$vuetify.goTo(item.ref,options)"
        >
          <span>{{ item.text }}</span>
        </v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Navbar',
  data: () => ({
    navBarTiles: [
      { text: 'Home', ref: '#home' },
      { text: 'Oferta', ref: '#offer' },
      { text: 'O nas', ref: '#about' },
      { text: 'Kontakt', ref: '#contact' },
    ],
  }),
  computed: {
    options() {
      return {
        duration: 500,
        easing: 'easeInOutCubic',
        offset: 60,
      };
    },
  },
};
</script>

<style>
.navbarBorder:after {
    content:'';
    width:100%;
    height:1px;
    position:absolute;
    bottom:0;
    left:0;
    background:#5c5c5c80;
    border-radius:0px;
    z-index: -1;
}
.tab {
  background-color: transparent !important;
  position: relative !important;
  z-index: 70 !important;
}
.tab:before {
  background-color: transparent !important;
}
.tab:hover {
  background-color: transparent;
}
.titleFont {
  font-family: Futura,Trebuchet MS,Arial,sans-serif  !important;
  font-weight: 300 !important;
  text-align: center;
  margin: auto;
}

</style>
