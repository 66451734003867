<template>
  <v-card
    class="pa-0 flex-grow-1 card"
    :height="'100%'"
    :flat="false"
    :color="backgroundColor"
  >
    <v-card-title class=" text-uppercase pa-0 text-h4 text-md-h3 text-lg-h2">
      <span
        class="titleFont"
        :style="{'padding': titlePadding, color: titleColor }"
      >{{ title }}</span>
    </v-card-title>
    <v-row
      :style="{'padding': '0 ' + textHorizontalPadding + 'px' + ' 30px'}"
      class="text-justify text-caption text-md-body-2 text-lg-body-1 ma-0 font-weight-light"
    >
      <span class="subtitleFont">STACJA ZERO...<br>BO JUŻ DZIŚ MOŻESZ ZACZĄĆ OD NOWA</span>
      <span class="textFont">{{ text }}<br><br>{{ text2 }}</span>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'TextCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitleText: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      required: true,
    },
    text2: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#F0EDE9',
    },
    titleColor: {
      type: String,
      default: '#661140',
    },
  },
  data: () => ({

  }),
  computed: {
    cardSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 300;
        case 'sm': return 400;
        case 'md': return 500;
        case 'lg': return 600;
        case 'xl': return 700;
        default: return 600;
      }
    },
    textHorizontalPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 30;
        case 'sm': return 30;
        case 'md': return 60;
        case 'lg': return 90;
        case 'xl': return 160;
        default: return 120;
      }
    },
    titleVerticalPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 15;
        case 'sm': return 20;
        case 'md': return 30;
        case 'lg': return 40;
        case 'xl': return 70;
        default: return 40;
      }
    },
    titlePadding() {
      return `${this.titleVerticalPadding * 2}px 0 ${this.titleVerticalPadding}px 0`;
    },
  },
};
</script>

<style>
.card {
  overflow: hidden;
}
.textFont {
  color: rgba(0,0,0,0.8);
  margin: auto;
  text-align: justify;
}
.subtitleFont {
  color: rgba(0,0,0,0.6);
  margin: auto;
  text-align: center;
  padding-bottom: 12px;
}
.titleFont {
  font-family: Futura,Trebuchet MS,Arial,sans-serif  !important;
  text-align: center;
  margin: auto;
}
</style>
