<template>
  <v-card
    class="pa-0 flex-grow-1"
    :height="'100%'"
    :flat="false"
    :color="backgroundColor"
  >
    <v-card-title class=" text-uppercase pa-0 text-h4 text-md-h3 text-lg-h2">
      <span
        class="titleFont"
        :style="{'padding': titlePadding, color: titleColor }"
      >{{ title }}</span>
    </v-card-title>
    <v-row
      :style="{'padding': '0 ' + textHorizontalPadding + 'px' + ' 30px'}"
      class="text-justify text-caption text-md-body-2 text-lg-body-1 ma-0 font-weight-light"
    >
      <span class="textFont">{{ text }}</span>
      <v-row class="ma-0 pa-0 flex-grow-1 d-flex align-end justify-center ">
        <v-col
          v-for="img in images"
          :key="img.path"
          cols="3"
          class="text-caption text-lg-body-1 ma-0 font-weight-light"
        >
          <p
            class="titleFont"
            :style="{color: titleColor }"
          >
            {{ img.name }}
          </p>
          <v-img
            class="ma-1 ma-sm-2 ma-md-3 ma-xl-7"
            :src="require('../assets/icons/' + img.path)"
            lazy-src="../assets/stacja_blur.png"
            :aspect-ratio="1"
          />
        </v-col>
      </v-row>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'TextCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitleText: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      required: true,
    },
    backgroundColor: {
      type: String,
      default: '#F0EDE9',
    },
    titleColor: {
      type: String,
      default: '#661140',
    },
  },
  data: () => ({
    images: [
      { path: 'vegan.png', name: 'wegańskie' },
      { path: 'sugar.png', name: 'bez cukru' },
      { path: 'lactose.png', name: 'bez laktozy' },
      { path: 'gluten.png', name: 'bez glutenu' },
    ],
  }),
  computed: {
    textHorizontalPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 30;
        case 'sm': return 30;
        case 'md': return 60;
        case 'lg': return 70;
        case 'xl': return 160;
        default: return 120;
      }
    },
    titleVerticalPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 15;
        case 'sm': return 20;
        case 'md': return 30;
        case 'lg': return 40;
        case 'xl': return 70;
        default: return 40;
      }
    },
    titlePadding() {
      return `${this.titleVerticalPadding * 2}px 0 ${this.titleVerticalPadding}px 0`;
    },
  },
};
</script>

<style>
.textFont {
  color: rgba(0,0,0,0.8);
  margin: auto;
  text-align: justify;
}
.titleFont {
  font-family: Futura,Trebuchet MS,Arial,sans-serif  !important;
  text-align: center;
  margin: auto;
}
</style>
