<template>
  <div>
    <div class="d-none d-md-flex">
      <div
        class="rounded-circle circle"
        transition="fab-transition"
        :style="{width: logoCircleSize , height: logoCircleSize}"
      />
      <img
        alt="Logo"
        class="logo"
        src="../assets/stacja.svg"
        :width="logoIconSize"
      >
    </div>
    <div class="d-flex d-md-none">
      <img
        alt=""
        class="logo_small"
        src="../assets/logo.svg"
        :width="logoCircleSize"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  data: () => ({

    sizeFactor: 0,
    threshold: 400,
  }),
  computed: {
    initialSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 40;
        case 'sm': return 40;
        case 'md': return 150;
        case 'lg': return 190;
        case 'xl': return 240;
        default: return 200;
      }
    },
    shrinkedSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 35;
        case 'sm': return 35;
        case 'md': return 115;
        case 'lg': return 120;
        case 'xl': return 140;
        default: return 120;
      }
    },
    finalSize() {
      return this.initialSize - (this.initialSize - this.shrinkedSize) * this.sizeFactor;
    },
    logoCircleSize() {
      return `${this.finalSize.toString()}px`;
    },
    logoIconSize() {
      return (this.finalSize * 0.37).toString();
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.sizeFactor = window.scrollY > this.threshold ? 1 : window.scrollY / this.threshold;
    },
  },
};
</script>

<style>
.circle {
  width: 250px;
  height: 250px;
  background-color: white;
  position: fixed;
  left:0;
  right:0;
  top:20px;
  margin:auto;
  box-shadow: 0 0 20px 0px #5c5c5c80;
  z-index: 6;
}
.logo {
  position: fixed;
  left:0;
  right:0;
  top: 30px;
  margin:auto;
  z-index: 6;
}
.logo_small {
  position: fixed;
  left:0;
  right:0;
  top: 5px;
  margin:auto;
  z-index: 5;
}
</style>
