<template>
  <v-app>
    <Navbar :data="cardsData" />
    <v-main>
      <v-container
        :fluid="$vuetify.breakpoint.width < 2100"
        class="pa-0 pt-md-8 px-lg-8 ma-0 ma-md-auto "
        justify="center"
      >
        <Leaves />
        <Card
          id="home"
          v-bind="{...cardsData[0]}"
        >
          <TextCard v-bind="{...cardsData[0]}" />
        </Card>
        <Leaves :invert="true" />
        <Menu id="offer" />
        <Leaves />
        <DoubleCard
          id="about"
          :right-img-ref="'neon.jpg'"
          :left-img-ref="'lokal.jpg'"
        >
          <TextCardDouble v-bind="{...cardsData[1]}" />
        </DoubleCard>
        <Leaves :invert="true" />
        <ContactCard id="contact" />
        <Leaves />
      </v-container>
    </v-main>
    <Logo />
  </v-app>
</template>
<script>
import Navbar from './components/Navbar.vue';
import Card from './components/Card.vue';
import DoubleCard from './components/DoubleCard.vue';
import TextCard from './components/TextCard.vue';
import TextCardDouble from './components/TextCardDouble.vue';
import Leaves from './components/Leaves.vue';
import Menu from './components/Menu.vue';
import Logo from './components/Logo.vue';
import ContactCard from './components/ContactCard.vue';

export default {
  name: 'App',

  components: {
    Navbar,
    Card,
    DoubleCard,
    TextCard,
    TextCardDouble,
    Leaves,
    Menu,
    Logo,
    ContactCard,
  },

  data: () => ({
    cardsData: [
      {
        text: 'Stacja Zero jest miejscem, w którym chcemy szerzyć idee świadomych wyborów żywieniowych. Naszą ofertę tworzymy z naturalnych składników pochodzących głównie od lokalnych dostawców. We wszystkich naszych produktach wykluczamy cukier, który zastępujemy jego lepszymi, zdrowszymi i mniej kalorycznymi zamiennikami. Staramy się, żeby każdy mógł znaleźć u nas coś odpowiedniego dla siebie, niezależnie od swoich wyborów czy nietolerancji pokarmowych. Zgodnie z tymi założeniami, oferujemy Wam produkty:',
        title: 'Stacja Zero',
        imgRefs: ['carousel/1.jpg', 'carousel/2.jpg', 'carousel/3.jpg', 'carousel/4.jpg'],
      },
      {
        text: 'Realizujemy projekt, który zakłada aktywizację zawodową młodych osób wykluczonych społecznie.  Nasza misja jest dualna - tworzymy i sprzedajemy dobre produkty, które polepszają jakość naszego życia, a także dajemy miejsce pracy osobom borykającym się z problemami.',
        text2: 'Tworząc Stację Zero podjęliśmy walkę nie tylko ze stereotypami społecznymi, ale także z mitami na temat zdrowej żywności mówiącymi, że zero cukru  równa się zero przyjemności z jedzenia.',
        title: 'O NAS',
        imgRef: 'baton.jpg',
        titleColor: '#37682b',
        backgroundColor: '#ecc7a6',
      },
    ],
    counter: 0,
    imagesLength: 0,
  }),
  created() {
    const imgs = document.images;
    this.imagesLength = imgs.length;
    [].forEach.call(imgs, (img) => {
      if (img.complete) { this.incrementCounter(); } else { img.addEventListener('load', this.incrementCounter, false); }
    });
  },
  methods: {
    incrementCounter() {
      this.counter += 1;
      if (this.counter === this.imagesLength) {
        console.log('All images loaded!');
      }
    },
  },
};
</script>

<style>
.background {
  background: url('./assets/background_small.png');
  background-repeat: repeat-y;
  background-size: 100% auto;

}
@font-face {
  font-family: 'YellowPeas';
  src: url(~@/fonts/Yellow-Peas-Regular-400.otf) format("opentype");
}

</style>
