<template>
  <v-img
    :src="require('../assets/leaves_horizontal.png')"
    eager
    :height="height"
    :width="'100%'"
    class="flex-grow-1 leaves"
    :class="{invertimage :invert}"
  />
</template>

<script>
export default {
  name: 'Leaves',
  props: {
    invert: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 80;
        case 'sm': return 100;
        case 'md': return 130;
        case 'lg': return 170;
        case 'xl': return 220;
        default: return 140;
      }
    },
  },
};
</script>

<style>
.leaves {
    opacity: 0.6;
}
.invertimage {
    transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
</style>
