<template>
  <v-card
    class="pa-0 ma-0"
    :elevation="6"
    :outlined="false"
  >
    <v-row
      class="pa-0 ma-0   flex-column flex-md-row"
      :class="{ 'flex-md-row-reverse': reversed}"
    >
      <v-col class="pa-0 ma-0">
        <v-carousel
          cycle
          :height="cardSize"
          hide-delimiter-background
          :show-arrows="false"
        >
          <v-carousel-item
            v-for="imgRef in imgRefs"
            :key="imgRef"
          >
            <v-img
              :src="require('../assets/' + imgRef)"
              eager
              lazy-src="../assets/stacja_blur.png"
              :aspect-ratio="1"
              height="100%"
              width="100%"
              class="flex-grow-1"
            >
              <template #placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  />
                </v-row>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col class="pa-0 ma-0">
        <slot />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
  name: 'Card',
  props: {
    imgRefs: {
      type: Array,
      required: true,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({

  }),
  computed: {
    cardSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return this.$vuetify.breakpoint.width * 0.66;
        case 'sm': return this.$vuetify.breakpoint.width * 0.66;
        case 'md': return 525;
        case 'lg': return 650;
        case 'xl': return 750;
        default: return 600;
      }
    },
  },
};
</script>

<style>
</style>
