<template>
  <v-sheet
    class="mx-auto"
    elevation="0"
  >
    <div
      class="text-uppercase d-flex align-center justify-center text-h4 text-md-h3 text-lg-h2 "
    >
      <span
        class="menutitle futuraFont"
      >
        {{ 'OFERTA' }}
      </span>
    </div>
    <v-slide-group
      v-model="model"
      class="d-flex justify-center align-center slides transparent"
      center-active
      :show-arrows="!this.$vuetify.breakpoint.mobile"
    >
      <v-slide-item
        v-for="(n, index) in categories"
        :key="n.name"
        v-slot="{ toggle }"
      >
        <v-sheet
          :width="activeWidth - unActiveMargin"
          :height="activeHeight"
          tile
          color="#00000000"
          class="transparentTile"
          :class="{firstElement: index==0, lastElement:index==(categories.length -1)}"
        >
          <v-sheet
            :id="n.name"
            class="pa-1 tileStyle"
            @click="toggle"
          >
            <v-img
              width="100%"
              :src="require('../assets/categories/' + n.name + '/icon.jpg')"
              lazy-src="../assets/stacja_blur.png"
              :aspect-ratio="1"
            />
          </v-sheet>
        </v-sheet>
      </v-slide-item>
    </v-slide-group>
    <v-expand-transition>
      <v-sheet
        v-if="model != null"
        tile
        class="pa-0 ma-0"
        elevation="10"
      >
        <v-row
          class="pa-0 ma-0   flex-column-reverse flex-md-row"
        >
          <v-col class="pa-0 ma-0">
            <v-sheet
              v-if="!shouldShowModal"
              :height="imageSize"
            >
              <v-img
                :src="activeProduct == null ? require(`../assets/categories/${selectedCategory}/colage.jpg`) : require(`../assets/categories/${selectedCategory}/${activeProductName.replace(/[^a-zA-Z ]/g, '')}.jpg`)"
                lazy-src="../assets/stacja_blur.png"
                width="100%"
                height="100%"
                :aspect-ratio="1"
                class="pa-0 ma-0 flex-grow-1"
              >
                <template #placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    />
                  </v-row>
                </template>
              </v-img>
            </v-sheet>
          </v-col>
          <v-col class="pa-0 ma-0">
            <v-card
              class="pa-0 py-md-2 px-md-6 px-lg-12 px-xl-16 flex-grow-1 overflow-y-auto"
              :min-height="imageSize"
              :max-height="cardSize"
              :flat="false"
            >
              <v-card
                v-for="(item, index) in products[selectedCategory]"
                :id="getProductId(index)"
                :key="item.nazwa"
                :value="item.active"
                outlined
                elevation="5"
                :ripple="false"
                class="rounded-lg pa-3 ma-3 pa-md-2 ma-md-2 pa-xl-4 ma-xl-4"
                :class="selectedCategory !== 'inne' && 'cardStyle'"
                @click.native.stop="selectedCategory !== 'inne' && setSelectedProduct(item, index)"
              >
                <div class="d-flex flex-row justify-center align-center">
                  <v-img
                    :src="require(`../assets/categories/${selectedCategory}/${capitalizeFirstLetter(item.nazwa).replace(/[^a-zA-Z ]/g, '')}.jpg`)"
                    lazy-src="../assets/stacja_blur.png"
                    :height="productIconSize"
                    :width="productIconSize"
                    class="ma-1 ma-md-2 mr-2 mr-md-3 mr-lg-4 flex-grow-0 rounded-circle"
                  />
                  <v-container
                    fluid
                    ma-0
                    pa-0
                    fill-height
                  >
                    <v-row
                      align="start"
                      no-gutters
                      dense
                      :style="`height: ${productIconSize/2}px;`"
                      class="pa-1 pa-md-2"
                    >
                      <div
                        class="flex-grow-1 futuraFont"
                        :style="{'font-size': `${productFontSize}px !important`, color: 'rgba(0, 0, 0, 0.7)'}"
                      >
                        {{ capitalizeFirstLetter(item.nazwa) }}
                      </div>
                    </v-row>
                    <v-row
                      v-if="selectedCategory !== 'napoje'"
                      align="end"
                      no-gutters
                      dense
                      :style="`height: ${productIconSize/2}px;`"
                      class="pa-0 ma-0"
                    >
                      <v-col
                        cols="4"
                        class="futuraFont"
                        :style="{'font-size': `${ingredientFontSize - 1}px !important`, color: 'rgba(0, 0, 0, 0.7)'}"
                      >
                        Porcja:<span :style="{'font-size': `${ingredientFontSize}px !important`,color: 'rgba(153, 4, 59, 0.95)'}">{{ getPortionData(item.porcja) }}</span>
                      </v-col>
                      <v-col
                        cols="4"
                        class="futuraFont"
                        :style="{'font-size': `${ingredientFontSize - 1}px !important`, color: 'rgba(0, 0, 0, 0.7)'}"
                      >
                        Kcal/100g:<span :style="{'font-size': `${ingredientFontSize}px !important`,color: 'rgba(153, 4, 59, 0.95)'}">{{ getKcalData(item.kcal) }}</span>
                      </v-col>
                      <v-col
                        cols="4"
                        class="productTitle futuraFont"
                        :style="{'font-size': `${ingredientFontSize - 1}px !important`, color: 'rgba(0, 0, 0, 0.7)'}"
                      >
                        Cena:<span :style="{'font-size': `${ingredientFontSize}px !important`,color: 'rgba(153, 4, 59, 0.95)'}">{{ getPrice(item.cena) }}</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
                <v-expand-transition>
                  <div v-show="!shouldShowModal && activeProduct===index">
                    <!-- <v-divider /> -->
                    <v-row class="ma-0 pa-0 flex-grow-1 d-flex ">
                      <v-col
                        v-for="img in  images.filter((e) => item[e.name])"
                        :key="img.path"
                        cols="3"
                      >
                        <v-tooltip
                          top
                          class="flex-grow-0"
                        >
                          <template #activator="{ on, attrs }">
                            <v-img
                              :src="require('../assets/icons/' + img.path)"
                              :height="productIconSize"
                              :width="productIconSize"
                              lazy-src="../assets/stacja_blur.png"
                              :aspect-ratio="1"
                              v-bind="attrs"
                              v-on="on"
                            />
                          </template>
                          <span>{{ img.text }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-list-item
                      v-if="selectedCategory !== 'napoje'"
                      :style="{'min-height': '0px !important'}"
                    >
                      <v-list-item-content class="pb-1 pb-md-2 pb-lg-3">
                        <v-list-item-title>
                          <div
                            class="futuraFont"
                            :style="{'font-size': `${ingredientFontSize}px !important`, color: 'rgba(0, 0, 0, 0.7)'}"
                          >
                            {{ "Skład:" }}
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-for="child in item.sklad"
                      :key="child.sklad"
                      dense
                      :style="{'min-height': '0px !important'}"
                    >
                      <v-list-item-content class="pa-0">
                        <v-list-item-title>
                          <div
                            class="futuraFont"
                            :style="{'font-size': `${ingredientFontSize}px !important`, color: 'rgba(0, 0, 0, 0.7)'}"
                          >
                            {{ getIngredient(child) }}
                          </div>
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="selectedCategory === 'napoje'">
                          <div
                            class="futuraFont"
                            :style="{'font-size': `${ingredientFontSize}px !important`, color: 'rgba(0, 0, 0, 0.5)'}"
                          >
                            {{ getIngredientData(child) }}
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-expand-transition>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </v-expand-transition>
    <v-dialog
      v-model="productModal"
      scrollable
      class="rounded-xl"
      style="border-radius: 100px !important"
      :width="$vuetify.breakpoint.width*0.75"
    >
      <v-card
        v-if="activeProduct != null"
        class="d-flex flex-column dialogCard"
      >
        <v-img
          class="flex-grow-0 rounded-b-xl"
          :aspect-ratio="1.3"
          :src="require(`../assets/categories/${selectedCategory}/${capitalizeFirstLetter(activeProductData.nazwa).replace(/[^a-zA-Z ]/g, '')}.jpg`)"
        >
          <div class="d-flex flex-row-reverse">
            <v-btn
              icon
              x-large
              color="white"
              @click="productModal = false; activeProduct = null;"
            >
              <v-icon large>
                mdi-close-box
              </v-icon>
            </v-btn>
          </div>
        </v-img>
        <v-row
          align="start"
          no-gutters
          dense
          class="pa-1 ma-0 pt-2 pt-sm-3 mx-2 mx-sm-4  flex-grow-0"
        >
          <div
            class="flex-grow-0 futuraFont"
            :style="{'font-size': `${18}px !important`, color: 'rgba(0, 0, 0, 0.95)'}"
          >
            {{ capitalizeFirstLetter(activeProductData.nazwa) }}
          </div>
        </v-row>
        <v-row
          v-if="selectedCategory !== 'napoje'"
          align="end"
          no-gutters
          dense
          class="pa-1 pt-1 mx-2 mx-sm-4 "
        >
          <v-col
            cols="9"
            class="futuraFont d-flex align-center justify-start"
            :style="{'font-size': `${ingredientFontSize}px !important`, color: 'rgba(0, 0, 0, 0.7)'}"
          >
            <v-icon small>
              mdi-scale-balance
            </v-icon>
            {{ getPortionData(activeProductData.porcja) }}

            <v-icon
              small
              class="pl-2 align-self-center"
            >
              mdi-fire
            </v-icon>
            {{ getKcalData(activeProductData.kcal) }} kcal/100g
          </v-col>
          <v-col
            cols="3"
            class="productTitle futuraFont text-right pr-2 font-weight-bold"
            :style="{'font-size': `${ingredientFontSize + 2}px !important`, color: 'rgba(0, 0, 0, 0.7)'}"
          >
            {{ getPrice(activeProductData.cena) }}
          </v-col>
        </v-row>
        <v-row
          dense
          class="ma-2 pa-0 ma-sm-4 d-flex"
        >
          <v-col
            v-for="img in activeImages"
            :key="img.path"
            cols="3"
            class="pa-0 ma-0"
          >
            <v-tooltip
              v-if="activeProductData[img.name]"
              top
              class="flex-grow-0"
            >
              <template #activator="{ on, attrs }">
                <v-img
                  class="ma-auto"
                  :src="require('../assets/icons/' + img.path)"
                  lazy-src="../assets/stacja_blur.png"
                  :height="productIconSize"
                  :width="productIconSize"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span>{{ img.text }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-card-text
          style="max-height: 40%;"
          class="pa-0 px-2 px-sm-4 ma-0 mb-4 mb-sm-6"
        >
          <v-list-item
            v-if="selectedCategory !== 'napoje'"
            :style="{'min-height': '0px !important'}"
            class="pa-1 ma-0"
          >
            <v-list-item-content class="pb-1">
              <v-list-item-title>
                <div
                  class="futuraFont font-weight-bold"
                  :style="{'font-size': `${dialogFontSize + 2}px !important`, color: 'rgba(0, 0, 0, 0.7)'}"
                >
                  {{ "Skład:" }}
                </div>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="child in activeProductData.sklad"
            :key="child.sklad"
            dense
            class="pa-0 pl-1 ma-0"
            :style="{'min-height': '0px !important'}"
          >
            <v-list-item-content class="pa-0">
              <v-list-item-title>
                <div
                  class="futuraFont"
                  :style="{'font-size': `${dialogFontSize}px !important`, color: 'rgba(0, 0, 0, 0.7)'}"
                >
                  {{ getIngredient(child) }}
                </div>
              </v-list-item-title>
              <v-list-item-subtitle v-if="selectedCategory === 'napoje'">
                <div
                  class="futuraFont"
                  :style="{'font-size': `${dialogFontSize}px !important`, color: 'rgba(0, 0, 0, 0.5)'}"
                >
                  {{ getIngredientData(child) }}
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <!-- <v-card-actions class="mt-auto">
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="productModal = false"
          >
            Zamknij
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { gsap } from 'gsap';
import prod from '../assets/products';
import cat from '../assets/categories';

export default {
  name: 'Menu',
  data: () => ({
    model: undefined,
    activeProduct: null,
    productModal: false,
    activeProductName: '',
    categories: cat,
    products: prod,
    images: [
      { path: 'sugar.png', name: 'sugarfree', text: 'bez cukru' },
      { path: 'vegan.png', name: 'vegan', text: 'wegańskie' },
      { path: 'lactose.png', name: 'lactosefree', text: 'bez laktozy' },
      { path: 'gluten.png', name: 'glutenfree', text: 'bez glutenu' },
    ],
  }),
  computed: {
    shouldShowModal() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return true;
        case 'sm': return true;
        case 'md': return false;
        case 'lg': return false;
        case 'xl': return false;
        default: return false;
      }
    },
    activeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 140;
        case 'sm': return 190;
        case 'md': return 230;
        case 'lg': return 250;
        case 'xl': return 300;
        default: return 250;
      }
    },
    activeHeight() {
      return this.activeWidth;
    },
    unActiveWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 120;
        case 'sm': return 160;
        case 'md': return 190;
        case 'lg': return 200;
        case 'xl': return 240;
        default: return 200;
      }
    },
    unActiveHeight() {
      return this.unActiveWidth;
    },
    unActiveMargin() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 10;
        case 'sm': return 15;
        case 'md': return 20;
        case 'lg': return 25;
        case 'xl': return 30;
        default: return 25;
      }
    },
    cardSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 3000;
        case 'sm': return 3000;
        case 'md': return 500;
        case 'lg': return 600;
        case 'xl': return 700;
        default: return 600;
      }
    },
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 300;
        case 'sm': return 400;
        case 'md': return 500;
        case 'lg': return 600;
        case 'xl': return 700;
        default: return 600;
      }
    },
    itemSize() {
      return this.cardSize / 5;
    },
    selectedCategory() {
      return this.categories[this.model].name;
    },
    activeProductData() {
      return this.products[this.selectedCategory][this.activeProduct];
    },
    ingredientFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 13;
        case 'sm': return 15;
        case 'md': return 14;
        case 'lg': return 16;
        case 'xl': return 20;
        default: return 12;
      }
    },
    dialogFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 11;
        case 'sm': return 13;
        case 'md': return 14;
        case 'lg': return 16;
        case 'xl': return 20;
        default: return 12;
      }
    },
    activeImages() {
      return this.images.filter((e) => this.activeProductData[e.name]);
    },
    productFontSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 14;
        case 'sm': return 15;
        case 'md': return 16;
        case 'lg': return 18;
        case 'xl': return 22;
        default: return 16;
      }
    },
    productIconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 40;
        case 'sm': return 50;
        case 'md': return 70;
        case 'lg': return 90;
        case 'xl': return 110;
        default: return 70;
      }
    },
    breakpointName() {
      return this.$vuetify.breakpoint.name;
    },
  },
  watch: {
    model(newcat, oldcat) {
      if (this.activeProduct !== null) this.products[this.categories[oldcat].name][this.activeProduct].active = false;
      this.activeProduct = null;
      if (oldcat !== undefined) {
        gsap.to(`#${this.categories[oldcat].name}`, {
          width: this.unActiveWidth, height: this.unActiveHeight, ease: 'back.inOut(1)', duration: 0.5,
        });
      }
      if (newcat !== undefined) {
        gsap.to(`#${this.categories[newcat].name}`, {
          width: this.activeWidth, height: this.activeHeight, ease: 'back.inOut(1)', duration: 0.5,
        });
      }
    },
    breakpointName() {
      this.categories.forEach((v, ind) => {
        if (ind === this.model) {
          gsap.to(`#${v.name}`, {
            width: this.activeWidth, height: this.activeHeight, ease: 'back.inOut(1)', duration: 0.5,
          });
        } else {
          gsap.to(`#${v.name}`, {
            width: this.unActiveWidth, height: this.unActiveHeight, ease: 'back.inOut(1)', duration: 0.5,
          });
        }
      });
    },
  },
  mounted() {
    this.model = 0;
    this.categories.forEach((v, ind) => {
      if (ind === this.model) {
        gsap.to(`#${v.name}`, {
          width: this.activeWidth, height: this.activeHeight, ease: 'back.inOut(1)', duration: 0.5,
        });
      } else {
        gsap.to(`#${v.name}`, {
          width: this.unActiveWidth, height: this.unActiveHeight, ease: 'back.inOut(1)', duration: 0.5,
        });
      }
    });
  },
  methods: {
    getProductId(index) {
      return `product_${this.selectedCategory}_${index}`;
    },
    setSelectedProduct(item, index) {
      if (this.activeProduct !== null) this.products[this.selectedCategory][this.activeProduct].active = false;
      if (this.activeProduct === index) {
        this.activeProduct = null;
      } else {
        this.products[this.selectedCategory][index].active = true;
        this.activeProductName = this.capitalizeFirstLetter(this.products[this.selectedCategory][index].nazwa);
        setTimeout(
          () => {
            this.activeProduct = index;
            if (this.shouldShowModal) {
              this.productModal = true;
            }
            // this.$vuetify.goTo(`#${this.getProductId(index)}`,
            //   {
            //     duration: 500,
            //     easing: 'easeInOutCubic',
            //     offset: 0,
            //   });
          }, 20,
        );
      }
    },
    capitalizeFirstLetter(string) {
      if (string === undefined) return '';
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getPrice(price) {
      if (this.selectedCategory === 'napoje') return '';
      return `${price}`;
    },
    getPortionData(weight) {
      if (this.selectedCategory === 'napoje') return '';
      return `${weight}`;
    },
    getKcalData(kcal) {
      if (this.selectedCategory === 'napoje') return '';
      return `${kcal}`;
    },
    getIngredient(item) {
      if (this.selectedCategory === 'napoje') return this.capitalizeFirstLetter(item.rodzaj);
      return `${item.procent}% ${this.capitalizeFirstLetter(item.produkt)}`;
    },
    getIngredientData(item) {
      if (this.selectedCategory === 'napoje') {
        if (item.small) return `cena: 0,3l: ${item.small}, 0,5l: ${item.large}`;
        return `cena: ${item.cena}`;
      }
      return `waga: ${item.waga}g, kcal: ${item['kcal w 100g']}/100g`;
    },
  },
};
</script>

<style>
.tileStyle {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.cardStyle {
  cursor: pointer;
}
.firstElement {
  margin: 15px 0 15px 15px !important;
}
.lastElement {
  margin: 15px 15px 15px 0 !important;
}
.transparentTile {
  position: relative;
  margin: 15px 0;
}
.futuraFont {
  font-family: Futura,Trebuchet MS,Arial,sans-serif  !important;
  line-height: 120% !important;
}
.menutitle{
    opacity: 0.7;
    color: #5b8761 !important;
}
.extraPadding {
  margin: 25px 25px;
}
.productTitle {
  flex: 0 1 auto !important;
}
.slides .v-slide-group__content {
  justify-content: center;
  align-items: center;
}
.productClass * {
  margin: 0 !important;
}
.bottomGradient {
  background-image: linear-gradient(to top, rgba(119, 64, 64, 0.1) 100%, transparent 0%);
}
.v-dialog:not(.v-dialog--fullscreen) {
  border-radius: 24px;
}
.dialogCard {
  border-radius: 24px;
}
</style>
