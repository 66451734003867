<template>
  <v-card
    class="rounded-0 pa-0 ma-0"
    :elevation="6"
    :outlined="false"
  >
    <v-row
      class="pa-0 ma-0   flex-column-reverse flex-md-row mapTile"
    >
      <v-col
        class="pa-0 ma-0"
        :height="cardSize"
      >
        <v-card
          class="rounded-0 pa-0 ma-0"
          :elevation="0"
          :outlined="false"
          :height="cardSize"
        >
          <div
            id="map"
            class="mapTile flex-grow-1"
          />
        </v-card>
      </v-col>

      <v-col class="pa-0 ma-0">
        <v-card
          class="rounded-0 pa-0 flex-grow-1 d-flex flex-column"
          :height="'100%'"
          :flat="false"
          :color="'#661140'"
        >
          <div class="textTile">
            <span
              class="titleFont whiteText"
              :style="{margin:'auto',padding: '15px ' + textHorizontalPadding + 'px'}"
            >
              {{ 'Zadzwoń lub napisz na mail kontakt@stacjazero.pl i zamów produkty z naszej oferty, a przywieziemy je pod Twoje drzwi!' }}
              <br>
              {{ 'Przy zamówieniach powyżej 100 zł dowóz za darmo.' }}
              <br>{{ 'Pamiętaj żeby duże zamówienia składać odpowiednio wcześniej :) ' }}
            </span>
          </div>
          <div
            class="whiteTile"
            :style="{margin: '0 ' + whiteTileMarginRight + 'px ' + whiteTileMarginBottom + 'px 0',}"
          >
            <v-col class="pa-2 ma-0 text-caption text-md-body-2 text-xl-body-1">
              <div class="text-uppercase pa-0 text-h6 text-md-h5 text-lg-h4  pa-0 ma-0">
                <p class="titleFont headingText ">
                  {{ "KONTAKT" }}
                </p>
              </div>
              <v-row class="pa-0 ma-1">
                <p class="pa-0 ma-0 whiteText titleFont">
                  Stacja Zero
                </p>
              </v-row>
              <v-row class="pa-0 ma-1">
                <p class="pa-0 ma-0 whiteText titleFont">
                  ul. Pabianicka 132
                </p>
              </v-row>
              <v-row class="pa-0 ma-1">
                <p class="pa-0 ma-0 whiteText titleFont">
                  93-410 Łódź
                </p>
              </v-row>
              <v-row class="pa-0 ma-1" />
              <v-row class="pa-0 ma-1">
                <p class="pa-0 ma-0 whiteText titleFont">
                  <v-icon color="#BBDEB2">
                    mdi-phone
                  </v-icon>669 141 431
                </p>
              </v-row>
              <v-row class="pa-0 ma-1">
                <a
                  class="linkStyle pa-0 ma-0 whiteText titleFont"
                  :href="'mailto:kontakt@stacjazero.pl'"
                >
                  <v-icon color="#BBDEB2">mdi-email</v-icon>kontakt@stacjazero.pl
                </a>
              </v-row>
            </v-col>
            <v-col class="pa-2 ma-0 text-caption text-md-body-2 text-xl-body-1">
              <div class="px-2 text-uppercase pa-0 text-h6 text-md-h5 text-lg-h4 pa-0 ma-0">
                <p class="titleFont headingText">
                  {{ "GODZINY OTWARCIA" }}
                </p>
              </div>
              <v-row
                v-for="day in weekdays"
                :key="day.name"
                class="px-6 px-lg-7 px-xl-16 ma-1"
              >
                <div class="flex-grow-1 d-flex justify-space-between">
                  <p
                    class="pa-0 ma-0 whiteText titleFont"
                    :style="{'text-align': 'start !important'}"
                  >
                    {{ day.name }}
                  </p>
                  <p
                    class="pa-0 ma-0 whiteText titleFont"
                    :style="{'text-align': 'end !important'}"
                  >
                    {{ day.time }}
                  </p>
                </div>
              </v-row>
            </v-col>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import mapTheme from '../assets/map_style';

export default {
  name: 'Card',
  data: () => ({
    weekdays: [
      { name: 'Niedziela', time: 'Zamknięte' },
      { name: 'Poniedziałek', time: '11-19' },
      { name: 'Wtorek', time: '11-19' },
      { name: 'Środa', time: '11-19' },
      { name: 'Czwartek', time: '11-19' },
      { name: 'Piątek', time: '11-19' },
      { name: 'Sobota', time: '10-17' },
    ],
  }),
  computed: {
    cardSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 300;
        case 'sm': return 400;
        case 'md': return 500;
        case 'lg': return 600;
        case 'xl': return 700;
        default: return 600;
      }
    },
    textHorizontalPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 60;
        case 'sm': return 90;
        case 'md': return 20;
        case 'lg': return 90;
        case 'xl': return 240;
        default: return 120;
      }
    },
    titleVerticalPadding() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 15;
        case 'sm': return 20;
        case 'md': return 30;
        case 'lg': return 40;
        case 'xl': return 70;
        default: return 40;
      }
    },
    whiteTileMarginBottom() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 20;
        case 'sm': return 20;
        case 'md': return 30;
        case 'lg': return 40;
        case 'xl': return 50;
        default: return 20;
      }
    },
    whiteTileMarginRight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 0;
        case 'sm': return 0;
        case 'md': return 0;
        case 'lg': return 0;
        case 'xl': return 0;
        default: return 0;
      }
    },
    titlePadding() {
      return `${this.titleVerticalPadding * 2}px 0 ${this.titleVerticalPadding}px 0`;
    },
  },
  mounted() {
    const loader = new Loader({
      apiKey: 'AIzaSyC_Y4MpO2ZmcWyFmee77J39-MaM96tBMJQ',
      version: 'weekly',
      libraries: ['places'],
    });

    const positionCoords = { lat: 51.7218, lng: 19.4421 };
    const roadColor = '#d59563';
    const mapOptions = {
      zoom: 14,
      center: positionCoords,
      styles: mapTheme,
    };

    loader.load()
      .then(() => {
        const map = new google.maps.Map(document.getElementById('map'), mapOptions);
        const infowindow = new google.maps.InfoWindow();
        const request = {
          query: 'Filharmonia Smaku',
          fields: ['name', 'geometry'],
        };
        const service = new google.maps.places.PlacesService(map);
        function createMarker(place) {
          const marker = new google.maps.Marker({
            map,
            position: place.geometry.location,
          });
          google.maps.event.addListener(marker, 'click', () => {
            infowindow.setContent(place.name);
            infowindow.open(map, marker);
          });
        }
        service.findPlaceFromQuery(request, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            for (let i = 0; i < results.length; i += 1) {
              createMarker(results[i]);
            }
            map.setCenter(results[0].geometry.location);
          }
        });
      });
  },
};
</script>

<style>
.mapTile {
  width: 100%;
  height: 100%;
}
.textFont {
  margin: auto;
  text-align: justify;
}
.titleFont {
  font-family: Futura,Trebuchet MS,Arial,sans-serif  !important;
  font-weight: 100 !important;
  text-align: center;
  margin: auto;
  width: 100%;
}
.whiteText {
  color: white;
  opacity: 1;
}
.headingText {
  color: #BBDEB2;
  opacity: 0.9;
  letter-spacing: 2px;
}
.textTile {
  padding: 0;
  text-align: center;
  flex: 1;
  display: flex;
}
.whiteTile {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 0 !important;
  flex: 1;
  display: flex;
}
.linkStyle {
  color: #BBDEB2 !important;
  text-decoration: none !important;
}
</style>
