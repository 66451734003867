export default {
  ciasta:
    [
      {
        nazwa: 'Brownie z kremem czekoladowym',
        porcja: '300g',
        glutenfree: true,
        vegan: true,
        lactosefree: true,
        sugarfree: true,
        cena: '15,-',
        kcal: '404',
        sklad: [
          {
            produkt: 'daktyle',
            waga: 225,
            procent: 42.9,
            'kcal w 100g': 290,
            '%*kcal': 124.29,
          },
          {
            produkt: 'masło orzechowe z ziemnych',
            waga: 125,
            procent: 23.8,
            'kcal w 100g': 610,
            '%*kcal': 145.24,
          },
          {
            produkt: 'czekolada deserowa słodzona maltitolem',
            waga: 70,
            procent: 13.3,
            'kcal w 100g': 477,
            '%*kcal': 63.6,
          },
          {
            produkt: 'orzechy włoskie',
            waga: 60,
            procent: 11.4,
            'kcal w 100g': 666,
            '%*kcal': 76.11,
          },
          {
            produkt: 'kakao',
            waga: 30,
            procent: 5.7,
            'kcal w 100g': 310,
            '%*kcal': 17.71,
          },
          {
            produkt: 'olej kokosowy',
            waga: 15,
            procent: 2.9,
            'kcal w 100g': 900,
            '%*kcal': 25.71,
          },
        ],
      },
      {
        nazwa: 'Jagielnik z malinami',
        porcja: '300 g',
        glutenfree: true,
        vegan: true,
        lactosefree: true,
        sugarfree: true,
        cena: '15,-',
        kcal: '165',
        sklad: [
          {
            produkt: 'napój migdałowy',
            waga: 1000,
            procent: 52.2,
            'kcal w 100g': 30,
            '%*kcal': 15.67,
          },
          {
            produkt: 'kasza jaglana',
            waga: 200,
            procent: 10.4,
            'kcal w 100g': 348,
            '%*kcal': 36.36,
          },
          {
            produkt: 'malina',
            waga: 200,
            procent: 10.4,
            'kcal w 100g': 43,
            '%*kcal': 4.49,
          },
          {
            produkt: 'morele suszone',
            waga: 150,
            procent: 7.8,
            'kcal w 100g': 301,
            '%*kcal': 23.59,
          },
          {
            produkt: 'nerkowce',
            waga: 140,
            procent: 7.3,
            'kcal w 100g': 553,
            '%*kcal': 40.45,
          },
          {
            produkt: 'migdały',
            waga: 90,
            procent: 4.7,
            'kcal w 100g': 604,
            '%*kcal': 28.4,
          },
          {
            produkt: 'miód spadziowy',
            waga: 80,
            procent: 4.2,
            'kcal w 100g': 340,
            '%*kcal': 14.21,
          },
          {
            produkt: 'sok z cytryny',
            waga: 30,
            procent: 1.6,
            'kcal w 100g': 25,
            '%*kcal': 0.39,
          },
          {
            produkt: 'ksylitol',
            waga: 20,
            procent: 1,
            'kcal w 100g': 240,
            '%*kcal': 2.51,
          },
          {
            produkt: 'pektyna NH',
            waga: 4,
            procent: 0.2,
            'kcal w 100g': 160,
            '%*kcal': 0.33,
          },
        ],
      },
      {
        nazwa: 'Tofurnik z mango',
        porcja: '300g',
        glutenfree: true,
        vegan: true,
        lactosefree: true,
        sugarfree: true,
        cena: '15,-',
        kcal: '125',
        sklad: [
          {
            produkt: 'tofu naturalne',
            waga: 400,
            procent: 22,
            'kcal w 100g': 62,
            '%*kcal': 13.66,
          },
          {
            produkt: 'napój kokosowy',
            waga: 400,
            procent: 22,
            'kcal w 100g': 20,
            '%*kcal': 4.41,
          },
          {
            produkt: 'puree z mango',
            waga: 400,
            procent: 22,
            'kcal w 100g': 70,
            '%*kcal': 15.43,
          },
          {
            produkt: 'mango',
            waga: 200,
            procent: 11,
            'kcal w 100g': 70,
            '%*kcal': 7.71,
          },
          {
            produkt: 'mąka migdałowa',
            waga: 120,
            procent: 6.6,
            'kcal w 100g': 604,
            '%*kcal': 39.93,
          },
          {
            produkt: 'morele suszone',
            waga: 80,
            procent: 4.4,
            'kcal w 100g': 301,
            '%*kcal': 13.27,
          },
          {
            produkt: 'ksylitol',
            waga: 70,
            procent: 3.9,
            'kcal w 100g': 240,
            '%*kcal': 9.26,
          },
          {
            produkt: 'daktyle',
            waga: 50,
            procent: 2.8,
            'kcal w 100g': 290,
            '%*kcal': 7.99,
          },
          {
            produkt: 'sok z cytryny',
            waga: 40,
            procent: 2.2,
            'kcal w 100g': 25,
            '%*kcal': 0.55,
          },
          {
            produkt: 'jagody goji',
            waga: 25,
            procent: 1.4,
            'kcal w 100g': 349,
            '%*kcal': 4.81,
          },
          {
            produkt: 'olej kokosowy',
            waga: 15,
            procent: 0.8,
            'kcal w 100g': 900,
            '%*kcal': 7.44,
          },
          {
            produkt: 'pektyna NH',
            waga: 14,
            procent: 0.8,
            'kcal w 100g': 160,
            '%*kcal': 1.23,
          },
          {
            produkt: 'sól',
            waga: 1,
            procent: 0.1,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
        ],
      },
      {
        nazwa: 'Ciasto pistacjowe',
        porcja: '240 g',
        glutenfree: true,
        vegan: false,
        lactosefree: true,
        sugarfree: true,
        cena: '18,-',
        kcal: '235',
        sklad: [
          {
            produkt: 'napój owsiany',
            waga: 62.27,
            procent: 25.9,
            'kcal w 100g': 54,
            '%*kcal': 14.01,
          },
          {
            produkt: 'ksylitol',
            waga: 31.54,
            procent: 13.1,
            'kcal w 100g': 240,
            '%*kcal': 31.54,
          },
          {
            produkt: 'białko kurze',
            waga: 21.67,
            procent: 9,
            'kcal w 100g': 52,
            '%*kcal': 4.7,
          },
          {
            produkt: 'pistacje',
            waga: 19.67,
            procent: 8.2,
            'kcal w 100g': 621,
            '%*kcal': 50.9,
          },
          {
            produkt: 'jogurt bez laktozy',
            waga: 16.28,
            procent: 6.8,
            'kcal w 100g': 73,
            '%*kcal': 4.95,
          },
          {
            produkt: 'pestki dyni',
            waga: 15.6,
            procent: 6.5,
            'kcal w 100g': 446,
            '%*kcal': 28.99,
          },
          {
            produkt: 'żółtko kurze',
            waga: 14.63,
            procent: 6.1,
            'kcal w 100g': 63,
            '%*kcal': 3.84,
          },
          {
            produkt: 'cukier kokosowy',
            waga: 13.19,
            procent: 5.5,
            'kcal w 100g': 377,
            '%*kcal': 20.72,
          },
          {
            produkt: 'mąka z pestek dyni',
            waga: 12.21,
            procent: 5.1,
            'kcal w 100g': 446,
            '%*kcal': 22.69,
          },
          {
            produkt: 'rodzynki',
            waga: 8.14,
            procent: 3.4,
            'kcal w 100g': 286,
            '%*kcal': 9.7,
          },
          {
            produkt: 'miód wielokwiatowy',
            waga: 6.49,
            procent: 2.7,
            'kcal w 100g': 324,
            '%*kcal': 8.76,
          },
          {
            produkt: 'mąka ryżowa',
            waga: 5.94,
            procent: 2.5,
            'kcal w 100g': 348,
            '%*kcal': 8.61,
          },
          {
            produkt: 'pasta z pestek dyni',
            waga: 4.07,
            procent: 1.7,
            'kcal w 100g': 446,
            '%*kcal': 7.56,
          },
          {
            produkt: 'masło kakaowe',
            waga: 3.12,
            procent: 1.3,
            'kcal w 100g': 900,
            '%*kcal': 11.7,
          },
          {
            produkt: 'agar',
            waga: 1.56,
            procent: 0.7,
            'kcal w 100g': 306,
            '%*kcal': 1.99,
          },
          {
            produkt: 'skrobia ziemniaczana',
            waga: 1.43,
            procent: 0.6,
            'kcal w 100g': 357,
            '%*kcal': 2.13,
          },
          {
            produkt: 'tapioka',
            waga: 0.77,
            procent: 0.3,
            'kcal w 100g': 355,
            '%*kcal': 1.14,
          },
          {
            produkt: 'proszek do pieczenia bezglutenowy',
            waga: 0.77,
            procent: 0.3,
            'kcal w 100g': 150,
            '%*kcal': 0.48,
          },
          {
            produkt: 'sól',
            waga: 0.39,
            procent: 0.2,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
          {
            produkt: 'lecytyna słonecznikowa w płynie',
            waga: 0.26,
            procent: 0.1,
            'kcal w 100g': 800,
            '%*kcal': 0.87,
          },
        ],
      },
      {
        nazwa: 'Ciasto marchewkowe',
        porcja: '180 g',
        glutenfree: false,
        vegan: true,
        lactosefree: true,
        sugarfree: true,
        cena: '10,-',
        kcal: '289',
        sklad: [
          {
            produkt: 'mąka orkiszowa typ 630',
            waga: 300,
            procent: 18.9,
            'kcal w 100g': 364,
            '%*kcal': 68.94,
          },
          {
            produkt: 'marchew',
            waga: 300,
            procent: 18.9,
            'kcal w 100g': 41,
            '%*kcal': 7.77,
          },
          {
            produkt: 'ksylitol',
            waga: 250,
            procent: 15.8,
            'kcal w 100g': 240,
            '%*kcal': 37.88,
          },
          {
            produkt: 'olej z pestek winogron',
            waga: 200,
            procent: 12.6,
            'kcal w 100g': 828,
            '%*kcal': 104.55,
          },
          {
            produkt: 'woda',
            waga: 180,
            procent: 11.4,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
          {
            produkt: 'orzechy nerkowca',
            waga: 100,
            procent: 6.3,
            'kcal w 100g': 553,
            '%*kcal': 34.91,
          },
          {
            produkt: 'napój migdałowy',
            waga: 80,
            procent: 5.1,
            'kcal w 100g': 30,
            '%*kcal': 1.52,
          },
          {
            produkt: 'siemię lniane',
            waga: 60,
            procent: 3.8,
            'kcal w 100g': 500,
            '%*kcal': 18.94,
          },
          {
            produkt: 'syrop z agawy',
            waga: 50,
            procent: 3.2,
            'kcal w 100g': 310,
            '%*kcal': 9.79,
          },
          {
            produkt: 'sok z cytryny',
            waga: 30,
            procent: 1.9,
            'kcal w 100g': 25,
            '%*kcal': 0.47,
          },
          {
            produkt: 'malina liofilizowana',
            waga: 10,
            procent: 0.6,
            'kcal w 100g': 300,
            '%*kcal': 1.89,
          },
          {
            produkt: 'soda oczyszczona',
            waga: 7,
            procent: 0.4,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
          {
            produkt: 'imbir mielony',
            waga: 5,
            procent: 0.3,
            'kcal w 100g': 347,
            '%*kcal': 1.1,
          },
          {
            produkt: 'cynamon mielony',
            waga: 5,
            procent: 0.3,
            'kcal w 100g': 247,
            '%*kcal': 0.78,
          },
          {
            produkt: 'sól',
            waga: 5,
            procent: 0.3,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
          {
            produkt: 'gałka muszkatołowa',
            waga: 2,
            procent: 0.1,
            'kcal w 100g': 525,
            '%*kcal': 0.66,
          },
        ],
      },
      {
        nazwa: 'Chlebek bananowy',
        porcja: '300 g',
        glutenfree: false,
        vegan: true,
        lactosefree: true,
        sugarfree: true,
        cena: '15,-',
        kcal: '263',
        sklad: [
          {
            produkt: 'banany',
            waga: 520,
            procent: 52.7,
            'kcal w 100g': 97,
            '%*kcal': 51.16,
          },
          {
            produkt: 'mąka orkiszowa typ 630',
            waga: 275,
            procent: 27.9,
            'kcal w 100g': 364,
            '%*kcal': 101.52,
          },
          {
            produkt: 'olej z pestek winogron',
            waga: 110,
            procent: 11.2,
            'kcal w 100g': 828,
            '%*kcal': 92.37,
          },
          {
            produkt: 'ksylitol',
            waga: 75,
            procent: 7.6,
            'kcal w 100g': 240,
            '%*kcal': 18.26,
          },
          {
            produkt: 'proszek do pieczenia',
            waga: 3,
            procent: 0.3,
            'kcal w 100g': 53,
            '%*kcal': 0.16,
          },
          {
            produkt: 'soda oczyszczona',
            waga: 3,
            procent: 0.3,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
        ],
      },
      {
        nazwa: 'tarta jabłkowo-orzechowa',
        porcja: '180 g',
        glutenfree: false,
        vegan: true,
        lactosefree: true,
        sugarfree: true,
        cena: '15,-',
        kcal: '323',
        sklad: [
          {
            produkt: 'jabłka',
            waga: 269.1,
            procent: 24,
            'kcal w 100g': 52,
            '%*kcal': 12.5,
          },
          {
            produkt: 'mąka orkiszowa typ 680',
            waga: 149.45,
            procent: 13.3,
            'kcal w 100g': 345,
            '%*kcal': 46.05,
          },
          {
            produkt: 'cukier kokosowy',
            waga: 122.65,
            procent: 11,
            'kcal w 100g': 377,
            '%*kcal': 41.3,
          },
          {
            produkt: 'napój owsiany',
            waga: 96.2,
            procent: 8.6,
            'kcal w 100g': 54,
            '%*kcal': 4.64,
          },
          {
            produkt: 'pasta z orzechów laskowych',
            waga: 83.8,
            procent: 7.5,
            'kcal w 100g': 666,
            '%*kcal': 49.84,
          },
          {
            produkt: 'olej z pestek winogron',
            waga: 73.35,
            procent: 6.6,
            'kcal w 100g': 828,
            '%*kcal': 54.24,
          },
          {
            produkt: 'masło kakaowe',
            waga: 65.96,
            procent: 5.9,
            'kcal w 100g': 900,
            '%*kcal': 53.02,
          },
          {
            produkt: 'mąka z orzechów laskowych',
            waga: 61.9,
            procent: 5.5,
            'kcal w 100g': 666,
            '%*kcal': 36.82,
          },
          {
            produkt: 'puree z jabłek',
            waga: 61.44,
            procent: 5.5,
            'kcal w 100g': 52,
            '%*kcal': 2.85,
          },
          {
            produkt: 'ksylitol',
            waga: 49.74,
            procent: 4.4,
            'kcal w 100g': 240,
            '%*kcal': 10.66,
          },
          {
            produkt: 'woda',
            waga: 42.9,
            procent: 3.8,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
          {
            produkt: 'napój owsiany',
            waga: 13.05,
            procent: 1.2,
            'kcal w 100g': 54,
            '%*kcal': 0.63,
          },
          {
            produkt: 'siemię lniane',
            waga: 11.2,
            procent: 1,
            'kcal w 100g': 500,
            '%*kcal': 5,
          },
          {
            produkt: 'skrobia kukurydziana',
            waga: 6.75,
            procent: 0.6,
            'kcal w 100g': 351,
            '%*kcal': 2.12,
          },
          {
            produkt: 'imbir kandyzowany',
            waga: 2.7,
            procent: 0.2,
            'kcal w 100g': 289,
            '%*kcal': 0.7,
          },
          {
            produkt: 'agar',
            waga: 2.4,
            procent: 0.2,
            'kcal w 100g': 306,
            '%*kcal': 0.66,
          },
          {
            produkt: 'pektyna NH',
            waga: 1.92,
            procent: 0.2,
            'kcal w 100g': 160,
            '%*kcal': 0.27,
          },
          {
            produkt: 'xantana',
            waga: 1.75,
            procent: 0.2,
            'kcal w 100g': 76,
            '%*kcal': 0.12,
          },
          {
            produkt: 'sól',
            waga: 1.4,
            procent: 0.1,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
          {
            produkt: 'lecytyna słonecznikowa w płynie',
            waga: 1.36,
            procent: 0.1,
            'kcal w 100g': 800,
            '%*kcal': 0.97,
          },
          {
            produkt: 'kwas askorbinowy',
            waga: 0.3,
            procent: 0,
            'kcal w 100g': 297,
            '%*kcal': 0.08,
          },
          {
            produkt: 'wanilia',
            waga: 0.24,
            procent: 0,
            'kcal w 100g': 288,
            '%*kcal': 0.06,
          },
          {
            produkt: 'guma guar',
            waga: 0.15,
            procent: 0,
            'kcal w 100g': 349,
            '%*kcal': 0.05,
          },
        ],
      },
      {
        nazwa: 'tarta wiśniowo-sezamowa',
        porcja: '100 g',
        glutenfree: true,
        vegan: false,
        lactosefree: true,
        sugarfree: true,
        cena: '10,-',
        kcal: '453',
        sklad: [
          {
            produkt: 'olej z pestek winogron',
            waga: 99.2,
            procent: 16.5,
            'kcal w 100g': 828,
            '%*kcal': 137.01,
          },
          {
            produkt: 'ciemna czekolada 67%',
            waga: 88.6,
            procent: 14.8,
            'kcal w 100g': 510,
            '%*kcal': 75.37,
          },
          {
            produkt: 'wiśnie',
            waga: 87.9,
            procent: 14.7,
            'kcal w 100g': 49,
            '%*kcal': 7.18,
          },
          {
            produkt: 'cukier kokosowy',
            waga: 59.3,
            procent: 9.9,
            'kcal w 100g': 377,
            '%*kcal': 37.29,
          },
          {
            produkt: 'masło kakaowe',
            waga: 55.2,
            procent: 9.2,
            'kcal w 100g': 900,
            '%*kcal': 82.87,
          },
          {
            produkt: 'jaja',
            waga: 52.9,
            procent: 8.8,
            'kcal w 100g': 140,
            '%*kcal': 12.35,
          },
          {
            produkt: 'mąka ryżowa',
            waga: 42.8,
            procent: 7.1,
            'kcal w 100g': 348,
            '%*kcal': 24.84,
          },
          {
            produkt: 'skrobia kukurydziana',
            waga: 27.2,
            procent: 4.5,
            'kcal w 100g': 351,
            '%*kcal': 15.93,
          },
          {
            produkt: 'ksylitol',
            waga: 26.6,
            procent: 4.4,
            'kcal w 100g': 240,
            '%*kcal': 10.65,
          },
          {
            produkt: 'mąka z ciecierzycy',
            waga: 15.4,
            procent: 2.6,
            'kcal w 100g': 387,
            '%*kcal': 9.94,
          },
          {
            produkt: 'mąka migdałowa',
            waga: 11.6,
            procent: 1.9,
            'kcal w 100g': 604,
            '%*kcal': 11.69,
          },
          {
            produkt: 'tahini',
            waga: 9,
            procent: 1.5,
            'kcal w 100g': 659,
            '%*kcal': 9.89,
          },
          {
            produkt: 'kakao',
            waga: 7.8,
            procent: 1.3,
            'kcal w 100g': 310,
            '%*kcal': 4.03,
          },
          {
            produkt: 'prażony sezam kolorowy',
            waga: 7.2,
            procent: 1.2,
            'kcal w 100g': 660,
            '%*kcal': 7.93,
          },
          {
            produkt: 'miód wielokwiatowy',
            waga: 5.4,
            procent: 0.9,
            'kcal w 100g': 324,
            '%*kcal': 2.92,
          },
          {
            produkt: 'olej sezamowy',
            waga: 1.8,
            procent: 0.3,
            'kcal w 100g': 892,
            '%*kcal': 2.68,
          },
          {
            produkt: 'pektyna NH',
            waga: 1.1,
            procent: 0.2,
            'kcal w 100g': 160,
            '%*kcal': 0.29,
          },
          {
            produkt: 'proszek do pieczenia bezglutenowy',
            waga: 0.5,
            procent: 0.1,
            'kcal w 100g': 160,
            '%*kcal': 0.13,
          },
        ],
      },
      {
        nazwa: 'sernik z karmelem',
        porcja: '125 g',
        glutenfree: true,
        vegan: false,
        lactosefree: false,
        sugarfree: true,
        cena: '10,-',
        kcal: 270,
        sklad: [
          {
            produkt: 'twaróg półtłusty',
            waga: 286.55,
            procent: 28.4,
            'kcal w 100g': 116,
            '%*kcal': 32.98,
          },
          {
            produkt: 'ksylitol',
            waga: 219.25,
            procent: 21.8,
            'kcal w 100g': 240,
            '%*kcal': 52.2,
          },
          {
            produkt: 'cukier kokosowy',
            waga: 110.35,
            procent: 10.9,
            'kcal w 100g': 377,
            '%*kcal': 41.27,
          },
          {
            produkt: 'napój migdałowy',
            waga: 71.5,
            procent: 7.1,
            'kcal w 100g': 30,
            '%*kcal': 2.13,
          },
          {
            produkt: 'mąka migdałowa',
            waga: 47.75,
            procent: 4.7,
            'kcal w 100g': 604,
            '%*kcal': 28.61,
          },
          {
            produkt: 'olej z pestek winogron',
            waga: 45.65,
            procent: 4.5,
            'kcal w 100g': 828,
            '%*kcal': 37.5,
          },
          {
            produkt: 'mleko 3,2%',
            waga: 45.65,
            procent: 4.5,
            'kcal w 100g': 61,
            '%*kcal': 2.76,
          },
          {
            produkt: 'jaja',
            waga: 34.65,
            procent: 3.4,
            'kcal w 100g': 140,
            '%*kcal': 4.81,
          },
          {
            produkt: 'migdały',
            waga: 28.6,
            procent: 2.8,
            'kcal w 100g': 604,
            '%*kcal': 17.14,
          },
          {
            produkt: 'olej słonecznikowy',
            waga: 25.5,
            procent: 2.5,
            'kcal w 100g': 900,
            '%*kcal': 22.77,
          },
          {
            produkt: 'żółtko kurze',
            waga: 23.1,
            procent: 2.3,
            'kcal w 100g': 63,
            '%*kcal': 1.44,
          },
          {
            produkt: 'skrobia ziemniaczana',
            waga: 19.8,
            procent: 2,
            'kcal w 100g': 357,
            '%*kcal': 7.01,
          },
          {
            produkt: 'skrobia kukurydziana',
            waga: 19,
            procent: 1.9,
            'kcal w 100g': 351,
            '%*kcal': 6.62,
          },
          {
            produkt: 'mąka ryżowa',
            waga: 19,
            procent: 1.9,
            'kcal w 100g': 348,
            '%*kcal': 6.56,
          },
          {
            produkt: 'pasta migdałowa',
            waga: 9.5,
            procent: 0.9,
            'kcal w 100g': 604,
            '%*kcal': 5.69,
          },
          {
            produkt: 'proszek do pieczenia bezglutenowy',
            waga: 1.25,
            procent: 0.1,
            'kcal w 100g': 160,
            '%*kcal': 0.2,
          },
          {
            produkt: 'pektyna NH',
            waga: 0.4,
            procent: 0,
            'kcal w 100g': 160,
            '%*kcal': 0.06,
          },
          {
            produkt: 'sól',
            waga: 0.33,
            procent: 0,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
          {
            produkt: 'xantana',
            waga: 0.15,
            procent: 0,
            'kcal w 100g': 76,
            '%*kcal': 0.01,
          },
        ],
      },
      {
        nazwa: 'rolada wiśniowo-czekoladowa',
        porcja: '250 g',
        glutenfree: true,
        vegan: false,
        lactosefree: true,
        sugarfree: true,
        cena: '18,-',
        kcal: 206,
        sklad: [
          {
            produkt: 'wiśnie',
            waga: 225.25,
            procent: 23,
            'kcal w 100g': 49,
            '%*kcal': 11.27,
          },
          {
            produkt: 'czekolada deserowa słodzona maltitolem',
            waga: 215.25,
            procent: 22,
            'kcal w 100g': 477,
            '%*kcal': 104.8,
          },
          {
            produkt: 'jaja',
            waga: 189.74,
            procent: 19.4,
            'kcal w 100g': 140,
            '%*kcal': 27.11,
          },
          {
            produkt: 'napój migdałowy',
            waga: 155.98,
            procent: 15.9,
            'kcal w 100g': 30,
            '%*kcal': 4.78,
          },
          {
            produkt: 'ksylitol',
            waga: 99.82,
            procent: 10.2,
            'kcal w 100g': 240,
            '%*kcal': 24.45,
          },
          {
            produkt: 'mąka ryżowa',
            waga: 46.3,
            procent: 4.7,
            'kcal w 100g': 348,
            '%*kcal': 16.45,
          },
          {
            produkt: 'skrobia ziemniaczana',
            waga: 32.17,
            procent: 3.3,
            'kcal w 100g': 357,
            '%*kcal': 11.72,
          },
          {
            produkt: 'tapioka',
            waga: 5.6,
            procent: 0.6,
            'kcal w 100g': 355,
            '%*kcal': 2.03,
          },
          {
            produkt: 'ziarna kakaowca',
            waga: 3.54,
            procent: 0.4,
            'kcal w 100g': 602,
            '%*kcal': 2.18,
          },
          {
            produkt: 'pektyna NH',
            waga: 2.39,
            procent: 0.2,
            'kcal w 100g': 160,
            '%*kcal': 0.39,
          },
          {
            produkt: 'proszek do pieczenia bezglutenowy',
            waga: 2.1,
            procent: 0.2,
            'kcal w 100g': 160,
            '%*kcal': 0.34,
          },
          {
            produkt: 'kakao alkalizowane',
            waga: 1.42,
            procent: 0.1,
            'kcal w 100g': 310,
            '%*kcal': 0.45,
          },
          {
            produkt: 'agar',
            waga: 0.14,
            procent: 0,
            'kcal w 100g': 306,
            '%*kcal': 0.04,
          },
        ],
      },
      {
        nazwa: 'brownie z batata',
        porcja: '140 g',
        glutenfree: false,
        vegan: true,
        lactosefree: true,
        sugarfree: true,
        cena: '10,-',
        kcal: 241,
        sklad: [
          {
            produkt: 'bataty',
            waga: 500,
            procent: 43.2,
            'kcal w 100g': 86,
            '%*kcal': 37.17,
          },
          {
            produkt: 'mleczko kokosowe',
            waga: 200,
            procent: 17.3,
            'kcal w 100g': 229,
            '%*kcal': 39.59,
          },
          {
            produkt: 'mąka migdałowa',
            waga: 125,
            procent: 10.8,
            'kcal w 100g': 604,
            '%*kcal': 65.25,
          },
          {
            produkt: 'kasza jęczmienna',
            waga: 100,
            procent: 8.6,
            'kcal w 100g': 354,
            '%*kcal': 30.6,
          },
          {
            produkt: 'daktyle',
            waga: 60,
            procent: 5.2,
            'kcal w 100g': 290,
            '%*kcal': 15.04,
          },
          {
            produkt: 'woda',
            waga: 50,
            procent: 4.3,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
          {
            produkt: 'kakao',
            waga: 40,
            procent: 3.5,
            'kcal w 100g': 310,
            '%*kcal': 10.72,
          },
          {
            produkt: 'oliwa z oliwek',
            waga: 32,
            procent: 2.8,
            'kcal w 100g': 882,
            '%*kcal': 24.39,
          },
          {
            produkt: 'siemię lniane',
            waga: 20,
            procent: 1.7,
            'kcal w 100g': 500,
            '%*kcal': 8.64,
          },
          {
            produkt: 'ksylitol',
            waga: 15,
            procent: 1.3,
            'kcal w 100g': 240,
            '%*kcal': 3.11,
          },
          {
            produkt: 'pasta z  orzechów ziemnych',
            waga: 10,
            procent: 0.9,
            'kcal w 100g': 610,
            '%*kcal': 5.27,
          },
          {
            produkt: 'cynamon mielony',
            waga: 5,
            procent: 0.4,
            'kcal w 100g': 247,
            '%*kcal': 1.07,
          },
        ],
      },
      {
        nazwa: 'ciasto z cieciorki',
        porcja: '180 g',
        glutenfree: true,
        vegan: false,
        lactosefree: true,
        sugarfree: true,
        cena: '15,-',
        kcal: 136,
        sklad: [
          {
            produkt: 'ciecierzyca',
            waga: 240,
            procent: 26.4,
            'kcal w 100g': 120,
            '%*kcal': 31.65,
          },
          {
            produkt: 'jaja',
            waga: 195,
            procent: 21.4,
            'kcal w 100g': 140,
            '%*kcal': 30,
          },
          {
            produkt: 'banany',
            waga: 100,
            procent: 11,
            'kcal w 100g': 97,
            '%*kcal': 10.66,
          },
          {
            produkt: 'ksylitol',
            waga: 100,
            procent: 11,
            'kcal w 100g': 240,
            '%*kcal': 26.37,
          },
          {
            produkt: 'czekolada wegańska mleczna',
            waga: 85.68,
            procent: 9.4,
            'kcal w 100g': 610,
            '%*kcal': 57.43,
          },
          {
            produkt: 'żurawina suszona',
            waga: 80,
            procent: 8.8,
            'kcal w 100g': 308,
            '%*kcal': 27.08,
          },
          {
            produkt: 'mąka z migdałow',
            waga: 60,
            procent: 6.6,
            'kcal w 100g': 604,
            '%*kcal': 39.82,
          },
          {
            produkt: 'mleczko kokosowe',
            waga: 34.32,
            procent: 3.8,
            'kcal w 100g': 229,
            '%*kcal': 8.64,
          },
          {
            produkt: 'kakao',
            waga: 10,
            procent: 1.1,
            'kcal w 100g': 310,
            '%*kcal': 3.41,
          },
          {
            produkt: 'soda oczyszczona',
            waga: 5,
            procent: 0.5,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
        ],
      },
      {
        nazwa: 'jabłecznik',
        porcja: '300 g',
        glutenfree: false,
        vegan: false,
        lactosefree: true,
        sugarfree: true,
        cena: '10,-',
        kcal: 149,
        sklad: [
          {
            produkt: 'jabłka',
            waga: 1500,
            procent: 71.7,
            'kcal w 100g': 52,
            '%*kcal': 37.28,
          },
          {
            produkt: 'mąka orkiszowa typ 2000',
            waga: 300,
            procent: 14.3,
            'kcal w 100g': 334,
            '%*kcal': 47.9,
          },
          {
            produkt: 'masło',
            waga: 150,
            procent: 7.2,
            'kcal w 100g': 735,
            '%*kcal': 52.7,
          },
          {
            produkt: 'ksylitol',
            waga: 80,
            procent: 3.8,
            'kcal w 100g': 240,
            '%*kcal': 9.18,
          },
          {
            produkt: 'żółtko kurze',
            waga: 60,
            procent: 2.9,
            'kcal w 100g': 63,
            '%*kcal': 1.81,
          },
          {
            produkt: 'kwas askorbinowy',
            waga: 2,
            procent: 0.1,
            'kcal w 100g': 297,
            '%*kcal': 0.28,
          },
        ],
      },
      {
        nazwa: 'beza malinowa',
        porcja: '120 g',
        glutenfree: false,
        vegan: false,
        lactosefree: true,
        sugarfree: true,
        cena: '10,-',
        kcal: 204,
        sklad: [
          {
            produkt: 'maliny',
            waga: 500,
            procent: 25.6,
            'kcal w 100g': 43,
            '%*kcal': 11,
          },
          {
            produkt: 'śmietana 30%',
            waga: 400,
            procent: 20.5,
            'kcal w 100g': 291,
            '%*kcal': 59.57,
          },
          {
            produkt: 'mascarpone',
            waga: 250,
            procent: 12.8,
            'kcal w 100g': 429,
            '%*kcal': 54.89,
          },
          {
            produkt: 'erytrol',
            waga: 220,
            procent: 11.3,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
          {
            produkt: 'białko kurze',
            waga: 180,
            procent: 9.2,
            'kcal w 100g': 52,
            '%*kcal': 4.79,
          },
          {
            produkt: 'mąka kokosowa',
            waga: 160,
            procent: 8.2,
            'kcal w 100g': 443,
            '%*kcal': 36.27,
          },
          {
            produkt: 'źółtko kurze',
            waga: 80,
            procent: 4.1,
            'kcal w 100g': 63,
            '%*kcal': 2.58,
          },
          {
            produkt: 'masło',
            waga: 75,
            procent: 3.8,
            'kcal w 100g': 735,
            '%*kcal': 28.21,
          },
          {
            produkt: 'woda',
            waga: 40,
            procent: 2,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
          {
            produkt: 'żelatyna',
            waga: 20,
            procent: 1,
            'kcal w 100g': 344,
            '%*kcal': 3.52,
          },
          {
            produkt: 'skrobia ziemniaczana',
            waga: 15,
            procent: 0.8,
            'kcal w 100g': 357,
            '%*kcal': 2.74,
          },
          {
            produkt: 'sok z cytryny',
            waga: 10,
            procent: 0.5,
            'kcal w 100g': 25,
            '%*kcal': 0.13,
          },
          {
            produkt: 'wanilia',
            waga: 3,
            procent: 0.2,
            'kcal w 100g': 288,
            '%*kcal': 0.44,
          },
          {
            produkt: 'sól',
            waga: 1,
            procent: 0.1,
            'kcal w 100g': 0,
            '%*kcal': 0,
          },
        ],
      },
    ],
  chleby: [
    {
      nazwa: 'Chleb gryczany',
      cena: '8,-',
      kcal: '183',
      porcja: '650 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      sklad: [
        {
          produkt: 'woda',
          waga: 600,
          procent: 49.8,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'mąka gryczana',
          waga: 500,
          procent: 41.5,
          'kcal w 100g': 351,
          '%*kcal': 145.76,
        },
        {
          produkt: 'miód',
          waga: 32,
          procent: 2.7,
          'kcal w 100g': 312,
          '%*kcal': 8.29,
        },
        {
          produkt: 'słonecznik łuskany',
          waga: 30,
          procent: 2.5,
          'kcal w 100g': 584,
          '%*kcal': 14.55,
        },
        {
          produkt: 'siemię lniane',
          waga: 30,
          procent: 2.5,
          'kcal w 100g': 500,
          '%*kcal': 12.46,
        },
        {
          produkt: 'drożdże instant',
          waga: 7,
          procent: 0.6,
          'kcal w 100g': 325,
          '%*kcal': 1.89,
        },
        {
          produkt: 'sól',
          waga: 5,
          procent: 0.4,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
      ],
    },
    {
      nazwa: 'Chleb gryczano-ryżowy',
      cena: '8,-',
      kcal: '226',
      porcja: '600 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      sklad: [
        {
          produkt: 'woda',
          waga: 360,
          procent: 44.1,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'mąka gryczana',
          waga: 170,
          procent: 20.8,
          'kcal w 100g': 351,
          '%*kcal': 73.13,
        },
        {
          produkt: 'mąka ryżowa',
          waga: 100,
          procent: 12.3,
          'kcal w 100g': 348,
          '%*kcal': 42.65,
        },
        {
          produkt: 'skrobia ziemniaczana',
          waga: 50,
          procent: 6.1,
          'kcal w 100g': 357,
          '%*kcal': 21.88,
        },
        {
          produkt: 'słonecznik łuskany',
          waga: 30,
          procent: 3.7,
          'kcal w 100g': 584,
          '%*kcal': 21.47,
        },
        {
          produkt: 'siemię lniane',
          waga: 22,
          procent: 2.7,
          'kcal w 100g': 500,
          '%*kcal': 13.48,
        },
        {
          produkt: 'sezam czarny',
          waga: 20,
          procent: 2.5,
          'kcal w 100g': 673,
          '%*kcal': 16.5,
        },
        {
          produkt: 'sezam biały',
          waga: 20,
          procent: 2.5,
          'kcal w 100g': 646,
          '%*kcal': 15.83,
        },
        {
          produkt: 'sól',
          waga: 7,
          procent: 0.9,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'drożdże instant',
          waga: 7,
          procent: 0.9,
          'kcal w 100g': 325,
          '%*kcal': 2.79,
        },
      ],
    },
    {
      nazwa: 'Chleb żytni',
      cena: '10,-',
      kcal: '221',
      porcja: '750 g',
      glutenfree: false,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      sklad: [
        {
          produkt: 'woda',
          waga: 690,
          procent: 40.1,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'mąka żytnia typ 720',
          waga: 610,
          procent: 35.5,
          'kcal w 100g': 349,
          '%*kcal': 123.77,
        },
        {
          produkt: 'mąka orkiszowa typ 630',
          waga: 300,
          procent: 17.4,
          'kcal w 100g': 364,
          '%*kcal': 63.49,
        },
        {
          produkt: 'słonecznik łuskany',
          waga: 30,
          procent: 1.7,
          'kcal w 100g': 584,
          '%*kcal': 10.19,
        },
        {
          produkt: 'siemię lniane',
          waga: 30,
          procent: 1.7,
          'kcal w 100g': 500,
          '%*kcal': 8.72,
        },
        {
          produkt: 'sezam czarny',
          waga: 20,
          procent: 1.2,
          'kcal w 100g': 673,
          '%*kcal': 7.83,
        },
        {
          produkt: 'sezam biały',
          waga: 20,
          procent: 1.2,
          'kcal w 100g': 646,
          '%*kcal': 7.51,
        },
        {
          produkt: 'sól',
          waga: 20,
          procent: 1.2,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
      ],
    },
    {
      nazwa: 'Chleb orkiszowy',
      cena: '12,-',
      kcal: '217',
      porcja: '550 g',
      glutenfree: false,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      sklad: [
        {
          produkt: 'mąka orkiszowa typ 630',
          waga: 776,
          procent: 49.4,
          'kcal w 100g': 364,
          '%*kcal': 179.91,
        },
        {
          produkt: 'woda',
          waga: 607,
          procent: 38.7,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'mąka żytnia typ 720',
          waga: 167,
          procent: 10.6,
          'kcal w 100g': 349,
          '%*kcal': 37.12,
        },
        {
          produkt: 'sól',
          waga: 20,
          procent: 1.3,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
      ],
    },
    {
      nazwa: 'Chałka orkiszowa',
      cena: '10,-',
      kcal: '303',
      porcja: '430 g',
      glutenfree: false,
      vegan: false,
      lactosefree: false,
      sugarfree: true,
      sklad: [
        {
          produkt: 'mąka orkiszowa typ 630',
          waga: 500,
          procent: 52.5,
          'kcal w 100g': 364,
          '%*kcal': 190.98,
        },
        {
          produkt: 'mleko 3,2%',
          waga: 200,
          procent: 21,
          'kcal w 100g': 61,
          '%*kcal': 12.8,
        },
        {
          produkt: 'masło',
          waga: 75,
          procent: 7.9,
          'kcal w 100g': 735,
          '%*kcal': 57.84,
        },
        {
          produkt: 'jaja',
          waga: 65,
          procent: 6.8,
          'kcal w 100g': 140,
          '%*kcal': 9.55,
        },
        {
          produkt: 'miód wielokwiatowy',
          waga: 60,
          procent: 6.3,
          'kcal w 100g': 324,
          '%*kcal': 20.4,
        },
        {
          produkt: 'drożdże instant',
          waga: 30,
          procent: 3.1,
          'kcal w 100g': 325,
          '%*kcal': 10.23,
        },
        {
          produkt: 'żółtko kurze',
          waga: 20,
          procent: 2.1,
          'kcal w 100g': 63,
          '%*kcal': 1.32,
        },
        {
          produkt: 'sól',
          waga: 3,
          procent: 0.3,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
      ],
    },
  ],
  granola: [
    {
      nazwa: 'Granola czekoladowa',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '14,-',
      kcal: '418',
      sklad: [
        {
          produkt: 'płatki owsiane bezglutenowe',
          waga: 300,
          procent: 30.6,
          'kcal w 100g': 366,
          '%*kcal': 112.04,
        },
        {
          produkt: 'syrop z agawy',
          waga: 200,
          procent: 20.4,
          'kcal w 100g': 310,
          '%*kcal': 63.27,
        },
        {
          produkt: 'czekolada ciemna słodzona maltitolem',
          waga: 100,
          procent: 10.2,
          'kcal w 100g': 477,
          '%*kcal': 48.67,
        },
        {
          produkt: 'rodzynki',
          waga: 100,
          procent: 10.2,
          'kcal w 100g': 286,
          '%*kcal': 29.18,
        },
        {
          produkt: 'olej rzepakowy',
          waga: 60,
          procent: 6.1,
          'kcal w 100g': 884,
          '%*kcal': 54.12,
        },
        {
          produkt: 'orzechy laskowe',
          waga: 40,
          procent: 4.1,
          'kcal w 100g': 666,
          '%*kcal': 27.18,
        },
        {
          produkt: 'migdały',
          waga: 40,
          procent: 4.1,
          'kcal w 100g': 604,
          '%*kcal': 24.65,
        },
        {
          produkt: 'nerkowce',
          waga: 40,
          procent: 4.1,
          'kcal w 100g': 553,
          '%*kcal': 22.57,
        },
        {
          produkt: 'pestki dyni',
          waga: 40,
          procent: 4.1,
          'kcal w 100g': 446,
          '%*kcal': 18.2,
        },
        {
          produkt: 'kakao',
          waga: 30,
          procent: 3.1,
          'kcal w 100g': 310,
          '%*kcal': 9.49,
        },
        {
          produkt: 'malina liofilizowana',
          waga: 30,
          procent: 3.1,
          'kcal w 100g': 300,
          '%*kcal': 9.18,
        },
      ],
    },
    {
      nazwa: 'Granola gryczana z goji',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '14,-',
      kcal: '405',
      sklad: [
        {
          produkt: 'miód gryczany',
          waga: 600,
          procent: 30.6,
          'kcal w 100g': 340,
          '%*kcal': 104.08,
        },
        {
          produkt: 'płatki owsiane bezglutenowe',
          waga: 300,
          procent: 15.3,
          'kcal w 100g': 366,
          '%*kcal': 56.02,
        },
        {
          produkt: 'kasza gryczana niepalona',
          waga: 300,
          procent: 15.3,
          'kcal w 100g': 336,
          '%*kcal': 51.43,
        },
        {
          produkt: 'pestki dyni',
          waga: 240,
          procent: 12.2,
          'kcal w 100g': 446,
          '%*kcal': 54.61,
        },
        {
          produkt: 'siemię lniane',
          waga: 150,
          procent: 7.7,
          'kcal w 100g': 500,
          '%*kcal': 38.27,
        },
        {
          produkt: 'jagody goji',
          waga: 150,
          procent: 7.7,
          'kcal w 100g': 349,
          '%*kcal': 26.71,
        },
        {
          produkt: 'olej kokosowy',
          waga: 120,
          procent: 6.1,
          'kcal w 100g': 900,
          '%*kcal': 55.1,
        },
        {
          produkt: 'gryka ekspandowana',
          waga: 100,
          procent: 5.1,
          'kcal w 100g': 371,
          '%*kcal': 18.93,
        },
      ],
    },
    {
      nazwa: 'Granola z orzechem włoskim i truskawką',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '14,-',
      kcal: '418',
      sklad: [
        {
          produkt: 'płatki owsiane bezglutenowe',
          waga: 300,
          procent: 26.8,
          'kcal w 100g': 366,
          '%*kcal': 98.04,
        },
        {
          produkt: 'syrop z agawy',
          waga: 300,
          procent: 26.8,
          'kcal w 100g': 310,
          '%*kcal': 83.04,
        },
        {
          produkt: 'orzechy włoskie',
          waga: 160,
          procent: 14.3,
          'kcal w 100g': 666,
          '%*kcal': 95.14,
        },
        {
          produkt: 'rodzynki',
          waga: 100,
          procent: 8.9,
          'kcal w 100g': 286,
          '%*kcal': 25.54,
        },
        {
          produkt: 'morele suszone',
          waga: 100,
          procent: 8.9,
          'kcal w 100g': 301,
          '%*kcal': 26.88,
        },
        {
          produkt: 'olej rzepakowy',
          waga: 50,
          procent: 4.5,
          'kcal w 100g': 884,
          '%*kcal': 39.46,
        },
        {
          produkt: 'truskawka liofilizowana',
          waga: 50,
          procent: 4.5,
          'kcal w 100g': 328,
          '%*kcal': 14.64,
        },
        {
          produkt: 'sezam czarny',
          waga: 30,
          procent: 2.7,
          'kcal w 100g': 673,
          '%*kcal': 18.03,
        },
        {
          produkt: 'sezam biały',
          waga: 30,
          procent: 2.7,
          'kcal w 100g': 646,
          '%*kcal': 17.3,
        },
      ],
    },
    {
      nazwa: 'Granola z komosy ryżowej',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '14,-',
      kcal: '415',
      sklad: [
        {
          produkt: 'miód rzepakowy',
          waga: 900,
          procent: 36.4,
          'kcal w 100g': 340,
          '%*kcal': 123.89,
        },
        {
          produkt: 'słonecznik łuskany',
          waga: 300,
          procent: 12.1,
          'kcal w 100g': 584,
          '%*kcal': 70.93,
        },
        {
          produkt: 'komosa ryżowa',
          waga: 300,
          procent: 12.1,
          'kcal w 100g': 375,
          '%*kcal': 45.55,
        },
        {
          produkt: 'płatki owsiane bezglutenowe',
          waga: 300,
          procent: 12.1,
          'kcal w 100g': 366,
          '%*kcal': 44.45,
        },
        {
          produkt: 'komosa ekspandowana',
          waga: 150,
          procent: 6.1,
          'kcal w 100g': 426,
          '%*kcal': 25.87,
        },
        {
          produkt: 'płatki ryżowe',
          waga: 150,
          procent: 6.1,
          'kcal w 100g': 381,
          '%*kcal': 23.14,
        },
        {
          produkt: 'płatki gryczane',
          waga: 150,
          procent: 6.1,
          'kcal w 100g': 356,
          '%*kcal': 21.62,
        },
        {
          produkt: 'olej z pestek winogron',
          waga: 120,
          procent: 4.9,
          'kcal w 100g': 828,
          '%*kcal': 40.23,
        },
        {
          produkt: 'siemię lniane',
          waga: 100,
          procent: 4,
          'kcal w 100g': 500,
          '%*kcal': 20.24,
        },
      ],
    },
    {
      nazwa: 'Granola tropikalna',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '14,-',
      kcal: '392',
      sklad: [
        {
          produkt: 'syrop z agawy',
          waga: 500,
          procent: 36.2,
          'kcal w 100g': 310,
          '%*kcal': 112.32,
        },
        {
          produkt: 'płatki owsiane bezglutenowe',
          waga: 300,
          procent: 21.7,
          'kcal w 100g': 366,
          '%*kcal': 79.57,
        },
        {
          produkt: 'płatki kukurydziane',
          waga: 150,
          procent: 10.9,
          'kcal w 100g': 381,
          '%*kcal': 41.41,
        },
        {
          produkt: 'nasiona chia',
          waga: 100,
          procent: 7.2,
          'kcal w 100g': 486,
          '%*kcal': 35.22,
        },
        {
          produkt: 'olej rzepakowy',
          waga: 70,
          procent: 5.1,
          'kcal w 100g': 884,
          '%*kcal': 44.84,
        },
        {
          produkt: 'migdały',
          waga: 60,
          procent: 4.3,
          'kcal w 100g': 604,
          '%*kcal': 26.26,
        },
        {
          produkt: 'kasza jaglana',
          waga: 60,
          procent: 4.3,
          'kcal w 100g': 348,
          '%*kcal': 15.13,
        },
        {
          produkt: 'ananas liofilizowany',
          waga: 30,
          procent: 2.2,
          'kcal w 100g': 353,
          '%*kcal': 7.67,
        },
        {
          produkt: 'banan liofilizowany',
          waga: 30,
          procent: 2.2,
          'kcal w 100g': 334,
          '%*kcal': 7.26,
        },
        {
          produkt: 'marakuja liofilizowana',
          waga: 30,
          procent: 2.2,
          'kcal w 100g': 318,
          '%*kcal': 6.91,
        },
        {
          produkt: 'mango liofilizowane',
          waga: 30,
          procent: 2.2,
          'kcal w 100g': 310,
          '%*kcal': 6.74,
        },
        {
          produkt: 'sezam biały',
          waga: 20,
          procent: 1.4,
          'kcal w 100g': 646,
          '%*kcal': 9.36,
        },
      ],
    },
  ],
  desery: [
    {
      nazwa: 'Pudding kokosowy z tapioki',
      porcja: '250 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '189',
      sklad: [
        {
          produkt: 'mleczko kokosowe',
          waga: 1000,
          procent: 61,
          'kcal w 100g': 229,
          '%*kcal': 139.63,
        },
        {
          produkt: 'mango',
          waga: 300,
          procent: 18.3,
          'kcal w 100g': 70,
          '%*kcal': 12.8,
        },
        {
          produkt: 'gruszka',
          waga: 200,
          procent: 12.2,
          'kcal w 100g': 58,
          '%*kcal': 7.07,
        },
        {
          produkt: 'tapioka',
          waga: 100,
          procent: 6.1,
          'kcal w 100g': 355,
          '%*kcal': 21.65,
        },
        {
          produkt: 'ksylitol',
          waga: 30,
          procent: 1.8,
          'kcal w 100g': 320,
          '%*kcal': 5.85,
        },
        {
          produkt: 'syrop z agawy',
          waga: 10,
          procent: 0.6,
          'kcal w 100g': 310,
          '%*kcal': 1.89,
        },
      ],
    },
    {
      nazwa: 'Pudding chia czekoladowy',
      porcja: '250 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '126',
      sklad: [
        {
          produkt: 'napój migdałowy',
          waga: 1000,
          procent: 60.2,
          'kcal w 100g': 30,
          '%*kcal': 18.07,
        },
        {
          produkt: 'banan',
          waga: 300,
          procent: 18.1,
          'kcal w 100g': 97,
          '%*kcal': 17.53,
        },
        {
          produkt: 'nasiona chia',
          waga: 130,
          procent: 7.8,
          'kcal w 100g': 486,
          '%*kcal': 38.06,
        },
        {
          produkt: 'pasta z orzechów ziemnych',
          waga: 120,
          procent: 7.2,
          'kcal w 100g': 610,
          '%*kcal': 44.1,
        },
        {
          produkt: 'czekolada bez cukru',
          waga: 60,
          procent: 3.6,
          'kcal w 100g': 477,
          '%*kcal': 17.24,
        },
        {
          produkt: 'kakao',
          waga: 30,
          procent: 1.8,
          'kcal w 100g': 310,
          '%*kcal': 5.6,
        },
        {
          produkt: 'syrop z agawy',
          waga: 20,
          procent: 1.2,
          'kcal w 100g': 310,
          '%*kcal': 3.73,
        },
      ],
    },
    {
      nazwa: 'Pudding chia z orzechami',
      porcja: '250 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '119',
      sklad: [
        {
          produkt: 'napój migdałowy',
          waga: 1000,
          procent: 64.5,
          'kcal w 100g': 30,
          '%*kcal': 19.35,
        },
        {
          produkt: 'ananas',
          waga: 300,
          procent: 19.4,
          'kcal w 100g': 50,
          '%*kcal': 9.68,
        },
        {
          produkt: 'nasiona chia',
          waga: 130,
          procent: 8.4,
          'kcal w 100g': 486,
          '%*kcal': 40.76,
        },
        {
          produkt: 'pasta z orzechów ziemnych',
          waga: 120,
          procent: 7.7,
          'kcal w 100g': 610,
          '%*kcal': 47.23,
        },
        {
          produkt: 'syrop z agawy',
          waga: 10,
          procent: 0.6,
          'kcal w 100g': 310,
          '%*kcal': 2,
        },
      ],
    },
  ],
  torty: [
    {
      nazwa: 'tort malina/orzech/ciemna czekolada',
      porcja: '1000 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '90,-',
      kcal: '384',
      sklad: [
        {
          produkt: 'maliny',
          waga: 200,
          procent: 26.1,
          'kcal w 100g': 43,
          '%*kcal': 11.23,
        },
        {
          produkt: 'czekolada deserowa słodzona maltitolem',
          waga: 149.6,
          procent: 19.5,
          'kcal w 100g': 477,
          '%*kcal': 93.15,
        },
        {
          produkt: 'masło orzechowe z orzechów ziemnych',
          waga: 97.6,
          procent: 12.7,
          'kcal w 100g': 610,
          '%*kcal': 77.71,
        },
        {
          produkt: 'daktyle',
          waga: 85.8,
          procent: 11.2,
          'kcal w 100g': 290,
          '%*kcal': 32.48,
        },
        {
          produkt: 'mąka migdałowa',
          waga: 77,
          procent: 10.1,
          'kcal w 100g': 604,
          '%*kcal': 60.71,
        },
        {
          produkt: 'masło kakaowe',
          waga: 50,
          procent: 6.5,
          'kcal w 100g': 900,
          '%*kcal': 58.74,
        },
        {
          produkt: 'inulina',
          waga: 50,
          procent: 6.5,
          'kcal w 100g': 210,
          '%*kcal': 13.71,
        },
        {
          produkt: 'orzechy włoskie',
          waga: 22.8,
          procent: 3,
          'kcal w 100g': 666,
          '%*kcal': 19.82,
        },
        {
          produkt: 'izolat z białka z ziemniaka',
          waga: 15,
          procent: 2,
          'kcal w 100g': 377,
          '%*kcal': 7.38,
        },
        {
          produkt: 'kakao',
          waga: 11.4,
          procent: 1.5,
          'kcal w 100g': 310,
          '%*kcal': 4.61,
        },
        {
          produkt: 'pektyna NH',
          waga: 4,
          procent: 0.5,
          'kcal w 100g': 160,
          '%*kcal': 0.84,
        },
        {
          produkt: 'olej kokosowy',
          waga: 2.9,
          procent: 0.4,
          'kcal w 100g': 900,
          '%*kcal': 3.41,
        },
      ],
    },
    {
      nazwa: 'tort porzeczka/migdał/wanilia',
      porcja: '1000 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '90,-',
      kcal: '312',
      sklad: [
        {
          produkt: 'puree z czarnej porzeczki',
          waga: 400,
          procent: 21.3,
          'kcal w 100g': 30,
          '%*kcal': 6.4,
        },
        {
          produkt: 'cukier kokosowy',
          waga: 345,
          procent: 18.4,
          'kcal w 100g': 377,
          '%*kcal': 69.33,
        },
        {
          produkt: 'pasta migdałowa',
          waga: 245,
          procent: 13.1,
          'kcal w 100g': 604,
          '%*kcal': 78.88,
        },
        {
          produkt: 'woda',
          waga: 215,
          procent: 11.5,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'mąka migdałowa',
          waga: 150,
          procent: 8,
          'kcal w 100g': 604,
          '%*kcal': 48.29,
        },
        {
          produkt: 'napój migdałowy',
          waga: 145,
          procent: 7.7,
          'kcal w 100g': 30,
          '%*kcal': 2.32,
        },
        {
          produkt: 'olej słonecznikowy',
          waga: 80,
          procent: 4.3,
          'kcal w 100g': 900,
          '%*kcal': 38.38,
        },
        {
          produkt: 'masło kakaowe',
          waga: 75,
          procent: 4,
          'kcal w 100g': 900,
          '%*kcal': 35.98,
        },
        {
          produkt: 'skrobia kukurydziana',
          waga: 60,
          procent: 3.2,
          'kcal w 100g': 351,
          '%*kcal': 11.23,
        },
        {
          produkt: 'mąka ryżowa',
          waga: 60,
          procent: 3.2,
          'kcal w 100g': 348,
          '%*kcal': 11.13,
        },
        {
          produkt: 'inulina',
          waga: 50,
          procent: 2.7,
          'kcal w 100g': 210,
          '%*kcal': 5.6,
        },
        {
          produkt: 'sok z limonki',
          waga: 20,
          procent: 1.1,
          'kcal w 100g': 25,
          '%*kcal': 0.27,
        },
        {
          produkt: 'izolat z białka z ziemniaka',
          waga: 15,
          procent: 0.8,
          'kcal w 100g': 377,
          '%*kcal': 3.01,
        },
        {
          produkt: 'pektyna NH',
          waga: 8.5,
          procent: 0.5,
          'kcal w 100g': 160,
          '%*kcal': 0.72,
        },
        {
          produkt: 'proszek do pieczenia bezglutenowy',
          waga: 4,
          procent: 0.2,
          'kcal w 100g': 160,
          '%*kcal': 0.34,
        },
        {
          produkt: 'sól',
          waga: 2,
          procent: 0.1,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'wanilia',
          waga: 1,
          procent: 0.1,
          'kcal w 100g': 288,
          '%*kcal': 0.15,
        },
        {
          produkt: 'xantana',
          waga: 0.6,
          procent: 0,
          'kcal w 100g': 76,
          '%*kcal': 0.02,
        },
      ],
    },
    {
      nazwa: 'tort banan/kokos/ciemna czekolada',
      porcja: '1000 g',
      glutenfree: true,
      vegan: false,
      lactosefree: true,
      sugarfree: true,
      cena: '90,-',
      kcal: '236',
      sklad: [
        {
          produkt: 'banany',
          waga: 244.5,
          procent: 24.4,
          'kcal w 100g': 97,
          '%*kcal': 23.71,
        },
        {
          produkt: 'puree  z banana',
          waga: 235.35,
          procent: 23.5,
          'kcal w 100g': 97,
          '%*kcal': 22.82,
        },
        {
          produkt: 'puree z mango',
          waga: 85.95,
          procent: 8.6,
          'kcal w 100g': 70,
          '%*kcal': 6.01,
        },
        {
          produkt: 'jaja',
          waga: 56.75,
          procent: 5.7,
          'kcal w 100g': 140,
          '%*kcal': 7.94,
        },
        {
          produkt: 'olej kokosowy',
          waga: 55.35,
          procent: 5.5,
          'kcal w 100g': 900,
          '%*kcal': 49.8,
        },
        {
          produkt: 'pasta kokosowa 70%',
          waga: 45.25,
          procent: 4.5,
          'kcal w 100g': 703,
          '%*kcal': 31.8,
        },
        {
          produkt: 'ksylitol',
          waga: 41.4,
          procent: 4.1,
          'kcal w 100g': 240,
          '%*kcal': 9.93,
        },
        {
          produkt: 'sok z pomarańczy',
          waga: 41.4,
          procent: 4.1,
          'kcal w 100g': 45,
          '%*kcal': 1.86,
        },
        {
          produkt: 'wiórki kokosowe',
          waga: 37.75,
          procent: 3.8,
          'kcal w 100g': 698,
          '%*kcal': 26.34,
        },
        {
          produkt: 'cukier kokosowy',
          waga: 37.75,
          procent: 3.8,
          'kcal w 100g': 377,
          '%*kcal': 14.23,
        },
        {
          produkt: 'czekolada ciemna słodzona maltitolem',
          waga: 22.75,
          procent: 2.3,
          'kcal w 100g': 477,
          '%*kcal': 10.85,
        },
        {
          produkt: 'woda',
          waga: 19,
          procent: 1.9,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'mąka kokosowa',
          waga: 15,
          procent: 1.5,
          'kcal w 100g': 443,
          '%*kcal': 6.64,
        },
        {
          produkt: 'mąka ryżowa',
          waga: 15,
          procent: 1.5,
          'kcal w 100g': 348,
          '%*kcal': 5.22,
        },
        {
          produkt: 'skrobia kukurydziana',
          waga: 13.95,
          procent: 1.4,
          'kcal w 100g': 351,
          '%*kcal': 4.89,
        },
        {
          produkt: 'masło kakaowe',
          waga: 12.3,
          procent: 1.2,
          'kcal w 100g': 900,
          '%*kcal': 11.07,
        },
        {
          produkt: 'erytrol',
          waga: 12.3,
          procent: 1.2,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'pektyna NH',
          waga: 3.6,
          procent: 0.4,
          'kcal w 100g': 160,
          '%*kcal': 0.58,
        },
        {
          produkt: 'lecytyna słonecznikowa w płynie',
          waga: 2.25,
          procent: 0.2,
          'kcal w 100g': 800,
          '%*kcal': 1.8,
        },
        {
          produkt: 'skórka z pomaranczy',
          waga: 1.35,
          procent: 0.1,
          'kcal w 100g': 97,
          '%*kcal': 0.13,
        },
        {
          produkt: 'kwas askorbinowy',
          waga: 0.6,
          procent: 0.1,
          'kcal w 100g': 297,
          '%*kcal': 0.18,
        },
        {
          produkt: 'sól',
          waga: 0.5,
          procent: 0,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'guma guar',
          waga: 0.25,
          procent: 0,
          'kcal w 100g': 349,
          '%*kcal': 0.09,
        },
      ],
    },
    {
      nazwa: 'tort jogurt/malina/limonka',
      porcja: '1000 g',
      glutenfree: true,
      vegan: false,
      lactosefree: true,
      sugarfree: true,
      cena: '90,-',
      kcal: '108',
      sklad: [
        {
          produkt: 'puree z malin',
          waga: 277.4,
          procent: 25.2,
          'kcal w 100g': 43,
          '%*kcal': 10.85,
        },
        {
          produkt: 'woda',
          waga: 230.15,
          procent: 20.9,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'jogurt bez laktozy 0%',
          waga: 153.85,
          procent: 14,
          'kcal w 100g': 57,
          '%*kcal': 7.97,
        },
        {
          produkt: 'białko kurze',
          waga: 111.7,
          procent: 10.2,
          'kcal w 100g': 52,
          '%*kcal': 5.28,
        },
        {
          produkt: 'ksylitol',
          waga: 72.95,
          procent: 6.6,
          'kcal w 100g': 240,
          '%*kcal': 15.92,
        },
        {
          produkt: 'mąka migdałowa',
          waga: 42.75,
          procent: 3.9,
          'kcal w 100g': 604,
          '%*kcal': 23.48,
        },
        {
          produkt: 'mąka owsiana bezglutenowa',
          waga: 42.75,
          procent: 3.9,
          'kcal w 100g': 353,
          '%*kcal': 13.72,
        },
        {
          produkt: 'erytrol',
          waga: 34.65,
          procent: 3.2,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'mleko w proszku bez laktozy',
          waga: 28.85,
          procent: 2.6,
          'kcal w 100g': 351,
          '%*kcal': 9.21,
        },
        {
          produkt: 'cukier kokosowy',
          waga: 26.2,
          procent: 2.4,
          'kcal w 100g': 377,
          '%*kcal': 8.98,
        },
        {
          produkt: 'sok z limonki',
          waga: 20.8,
          procent: 1.9,
          'kcal w 100g': 25,
          '%*kcal': 0.47,
        },
        {
          produkt: 'mąka ryżowa',
          waga: 18.4,
          procent: 1.7,
          'kcal w 100g': 348,
          '%*kcal': 5.82,
        },
        {
          produkt: 'żelatyna',
          waga: 18.1,
          procent: 1.6,
          'kcal w 100g': 344,
          '%*kcal': 5.66,
        },
        {
          produkt: 'stewia',
          waga: 14.35,
          procent: 1.3,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'żółtko kurze',
          waga: 5.2,
          procent: 0.5,
          'kcal w 100g': 63,
          '%*kcal': 0.3,
        },
        {
          produkt: 'skrobia kukurydziana',
          waga: 1,
          procent: 0.1,
          'kcal w 100g': 351,
          '%*kcal': 0.32,
        },
        {
          produkt: 'xantana',
          waga: 0.35,
          procent: 0,
          'kcal w 100g': 76,
          '%*kcal': 0.02,
        },
        {
          produkt: 'proszek do pieczenia bezglutenowy',
          waga: 0.1,
          procent: 0,
          'kcal w 100g': 160,
          '%*kcal': 0.01,
        },
        {
          produkt: 'sól',
          waga: 0.1,
          procent: 0,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
      ],
    },
    {
      nazwa: 'tort chałwa/czekolada/migdał',
      porcja: '1000 g',
      glutenfree: true,
      vegan: false,
      lactosefree: false,
      sugarfree: true,
      cena: '90,-',
      kcal: '353',
      sklad: [
        {
          produkt: 'śmietanka 33%',
          waga: 264.71,
          procent: 26.5,
          'kcal w 100g': 318,
          '%*kcal': 84.18,
        },
        {
          produkt: 'czekolada deserowa słodzona maltitolem',
          waga: 156.25,
          procent: 15.6,
          'kcal w 100g': 477,
          '%*kcal': 74.53,
        },
        {
          produkt: 'chałwa',
          waga: 113.45,
          procent: 11.3,
          'kcal w 100g': 444,
          '%*kcal': 50.37,
        },
        {
          produkt: 'napój owsiany',
          waga: 93.75,
          procent: 9.4,
          'kcal w 100g': 54,
          '%*kcal': 5.06,
        },
        {
          produkt: 'cukier kokosowy',
          waga: 72.8,
          procent: 7.3,
          'kcal w 100g': 377,
          '%*kcal': 27.45,
        },
        {
          produkt: 'białko kurze',
          waga: 66.56,
          procent: 6.7,
          'kcal w 100g': 52,
          '%*kcal': 3.46,
        },
        {
          produkt: 'czekolada biała słodzona maltitolem',
          waga: 46.15,
          procent: 4.6,
          'kcal w 100g': 497,
          '%*kcal': 22.94,
        },
        {
          produkt: 'masło',
          waga: 43.68,
          procent: 4.4,
          'kcal w 100g': 735,
          '%*kcal': 32.1,
        },
        {
          produkt: 'migdały',
          waga: 33.28,
          procent: 3.3,
          'kcal w 100g': 604,
          '%*kcal': 20.1,
        },
        {
          produkt: 'woda',
          waga: 28.36,
          procent: 2.8,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'płatki kukurydziane',
          waga: 24.98,
          procent: 2.5,
          'kcal w 100g': 381,
          '%*kcal': 9.52,
        },
        {
          produkt: 'mąka ryżowa',
          waga: 24.46,
          procent: 2.4,
          'kcal w 100g': 348,
          '%*kcal': 8.51,
        },
        {
          produkt: 'pasta z orzechów laskowych',
          waga: 14.57,
          procent: 1.5,
          'kcal w 100g': 666,
          '%*kcal': 9.7,
        },
        {
          produkt: 'skrobia ziemniaczana',
          waga: 5.86,
          procent: 0.6,
          'kcal w 100g': 357,
          '%*kcal': 2.09,
        },
        {
          produkt: 'żelatyna',
          waga: 5.67,
          procent: 0.6,
          'kcal w 100g': 344,
          '%*kcal': 1.95,
        },
        {
          produkt: 'tapioka',
          waga: 2.94,
          procent: 0.3,
          'kcal w 100g': 355,
          '%*kcal': 1.04,
        },
        {
          produkt: 'proszek do pieczenia bezglutenowy',
          waga: 2.08,
          procent: 0.2,
          'kcal w 100g': 160,
          '%*kcal': 0.33,
        },
        {
          produkt: 'sól',
          waga: 0.45,
          procent: 0,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
      ],
    },
  ],
  przetwory: [
    {
      nazwa: 'Hummus',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '275',
      sklad: [
        {
          produkt: 'ciecierzyca',
          waga: 240,
          procent: 67.4,
          'kcal w 100g': 120,
          '%*kcal': 80.9,
        },
        {
          produkt: 'tahini',
          waga: 60,
          procent: 16.9,
          'kcal w 100g': 659,
          '%*kcal': 111.07,
        },
        {
          produkt: 'oliwa z oliwek',
          waga: 30,
          procent: 8.4,
          'kcal w 100g': 882,
          '%*kcal': 74.33,
        },
        {
          produkt: 'sok z cytryny',
          waga: 10,
          procent: 2.8,
          'kcal w 100g': 25,
          '%*kcal': 0.7,
        },
        {
          produkt: 'czosnek',
          waga: 6,
          procent: 1.7,
          'kcal w 100g': 152,
          '%*kcal': 2.56,
        },
        {
          produkt: 'kmin rzymski',
          waga: 5,
          procent: 1.4,
          'kcal w 100g': 375,
          '%*kcal': 5.27,
        },
        {
          produkt: 'sól',
          waga: 5,
          procent: 1.4,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
      ],
    },
    {
      nazwa: 'pasta z czerwonej fasoli',
      porcja: '202 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '130',
      sklad: [
        {
          produkt: 'czerwona fasola',
          waga: 240,
          procent: 51.4,
          'kcal w 100g': 66,
          '%*kcal': 33.92,
        },
        {
          produkt: 'cebula',
          waga: 150,
          procent: 32.1,
          'kcal w 100g': 33,
          '%*kcal': 10.6,
        },
        {
          produkt: 'oliwa z oliwek',
          waga: 40,
          procent: 8.6,
          'kcal w 100g': 882,
          '%*kcal': 75.55,
        },
        {
          produkt: 'czosnek',
          waga: 12,
          procent: 2.6,
          'kcal w 100g': 152,
          '%*kcal': 3.91,
        },
        {
          produkt: 'ocet winny',
          waga: 10,
          procent: 2.1,
          'kcal w 100g': 20,
          '%*kcal': 0.43,
        },
        {
          produkt: 'sól',
          waga: 10,
          procent: 2.1,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'kmin rzymski',
          waga: 5,
          procent: 1.1,
          'kcal w 100g': 375,
          '%*kcal': 4.01,
        },
      ],
    },
    {
      nazwa: 'pasta z pomidorów z bazylią',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '233',
      sklad: [
        {
          produkt: 'pomidory suszone w oleju',
          waga: 300,
          procent: 40.3,
          'kcal w 100g': 213,
          '%*kcal': 85.77,
        },
        {
          produkt: 'biała fasola',
          waga: 240,
          procent: 32.2,
          'kcal w 100g': 66,
          '%*kcal': 21.26,
        },
        {
          produkt: 'olej rzepakowy',
          waga: 100,
          procent: 13.4,
          'kcal w 100g': 884,
          '%*kcal': 118.66,
        },
        {
          produkt: 'sok z cytryny',
          waga: 50,
          procent: 6.7,
          'kcal w 100g': 25,
          '%*kcal': 1.68,
        },
        {
          produkt: 'skórka z cytryny',
          waga: 20,
          procent: 2.7,
          'kcal w 100g': 47,
          '%*kcal': 1.26,
        },
        {
          produkt: 'czosnek',
          waga: 10,
          procent: 1.3,
          'kcal w 100g': 152,
          '%*kcal': 2.04,
        },
        {
          produkt: 'sól',
          waga: 5,
          procent: 0.7,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'pieprz czarny',
          waga: 5,
          procent: 0.7,
          'kcal w 100g': 251,
          '%*kcal': 1.68,
        },
        {
          produkt: 'bazylia',
          waga: 15,
          procent: 2,
          'kcal w 100g': 23,
          '%*kcal': 0.46,
        },
      ],
    },
    {
      nazwa: 'majonez wegański',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '566',
      sklad: [
        {
          produkt: 'olej rzepakowy',
          waga: 250,
          procent: 63.1,
          'kcal w 100g': '884',
          '%*kcal': '558.08',
        },
        {
          produkt: 'aquafaba',
          waga: 125,
          procent: 31.6,
          'kcal w 100g': '18',
          '%*kcal': '5.68',
        },
        {
          produkt: 'ocet winny',
          waga: 10,
          procent: 2.5,
          'kcal w 100g': '20',
          '%*kcal': '0.51',
        },
        {
          produkt: 'musztarda sarepska',
          waga: 8,
          procent: 2,
          'kcal w 100g': '96',
          '%*kcal': '1.94',
        },
        {
          produkt: 'sól',
          waga: 3,
          procent: 0.8,
          'kcal w 100g': '0',
          '%*kcal': '0.00',
        },
        {
          produkt: 'suma',
          waga: 396,
          procent: 100,
          'kcal w 100g': '-',
          '%*kcal': '-',
        },
      ],
    },
    {
      nazwa: 'hummus z papryką wędzoną',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '264',
      sklad: [
        {
          produkt: 'ciecierzyca',
          waga: 240,
          procent: 51.1,
          'kcal w 100g': 120,
          '%*kcal': 61.28,
        },
        {
          produkt: 'tahini',
          waga: 60,
          procent: 12.8,
          'kcal w 100g': 659,
          '%*kcal': 84.13,
        },
        {
          produkt: 'oliwa z oliwek',
          waga: 50,
          procent: 10.6,
          'kcal w 100g': 882,
          '%*kcal': 93.83,
        },
        {
          produkt: 'sok z cytryny',
          waga: 50,
          procent: 10.6,
          'kcal w 100g': 25,
          '%*kcal': 2.66,
        },
        {
          produkt: 'skórka z cytryny',
          waga: 20,
          procent: 4.3,
          'kcal w 100g': 47,
          '%*kcal': 2,
        },
        {
          produkt: 'wędzona papryka w proszku',
          waga: 15,
          procent: 3.2,
          'kcal w 100g': 336,
          '%*kcal': 10.72,
        },
        {
          produkt: 'ocet balsamiczny',
          waga: 10,
          procent: 2.1,
          'kcal w 100g': 88,
          '%*kcal': 1.87,
        },
        {
          produkt: 'sól',
          waga: 10,
          procent: 2.1,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'czosnek',
          waga: 10,
          procent: 2.1,
          'kcal w 100g': 152,
          '%*kcal': 3.23,
        },
        {
          produkt: 'kmin rzymski',
          waga: 5,
          procent: 1.1,
          'kcal w 100g': 375,
          '%*kcal': 3.99,
        },
      ],
    },
    {
      nazwa: 'smalec wegański',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '210',
      sklad: [
        {
          produkt: 'fasola biała',
          waga: 240,
          procent: 42.8,
          'kcal w 100g': 66,
          '%*kcal': 28.24,
        },
        {
          produkt: 'cebula',
          waga: 100,
          procent: 17.8,
          'kcal w 100g': 33,
          '%*kcal': 5.88,
        },
        {
          produkt: 'jabłka',
          waga: 100,
          procent: 17.8,
          'kcal w 100g': 52,
          '%*kcal': 9.27,
        },
        {
          produkt: 'oliwa z oliwek',
          waga: 100,
          procent: 17.8,
          'kcal w 100g': 882,
          '%*kcal': 157.22,
        },
        {
          produkt: 'majeranek',
          waga: 7,
          procent: 1.2,
          'kcal w 100g': 271,
          '%*kcal': 3.38,
        },
        {
          produkt: 'pieprz czarny',
          waga: 5,
          procent: 0.9,
          'kcal w 100g': 375,
          '%*kcal': 3.34,
        },
        {
          produkt: 'sól',
          waga: 5,
          procent: 0.9,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'lubczyk suszony',
          waga: 4,
          procent: 0.7,
          'kcal w 100g': 392,
          '%*kcal': 2.8,
        },
      ],
    },
    {
      nazwa: 'tapenada z oliwek',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '247',
      sklad: [
        {
          produkt: 'oliwki zielone',
          waga: 240,
          procent: 37.8,
          'kcal w 100g': 145,
          '%*kcal': 54.8,
        },
        {
          produkt: 'oliwki czarne',
          waga: 240,
          procent: 37.8,
          'kcal w 100g': 122,
          '%*kcal': 46.11,
        },
        {
          produkt: 'oliwa z oliwek',
          waga: 100,
          procent: 15.7,
          'kcal w 100g': 882,
          '%*kcal': 138.9,
        },
        {
          produkt: 'sok z cytryny',
          waga: 30,
          procent: 4.7,
          'kcal w 100g': 25,
          '%*kcal': 1.18,
        },
        {
          produkt: 'czosnek',
          waga: 15,
          procent: 2.4,
          'kcal w 100g': 152,
          '%*kcal': 3.59,
        },
        {
          produkt: 'pieprz czarny',
          waga: 5,
          procent: 0.8,
          'kcal w 100g': 375,
          '%*kcal': 2.95,
        },
        {
          produkt: 'sól',
          waga: 5,
          procent: 0.8,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
      ],
    },
    {
      nazwa: 'pesto bazyliowe',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '474',
      sklad: [
        {
          produkt: 'bazylia',
          waga: 100,
          procent: 30.3,
          'kcal w 100g': 23,
          '%*kcal': 6.97,
        },
        {
          produkt: 'słonecznik łuskany',
          waga: 100,
          procent: 30.3,
          'kcal w 100g': 577,
          '%*kcal': 174.85,
        },
        {
          produkt: 'oliwa z oliwek',
          waga: 100,
          procent: 30.3,
          'kcal w 100g': 882,
          '%*kcal': 267.27,
        },
        {
          produkt: 'siemię lniane',
          waga: 10,
          procent: 3,
          'kcal w 100g': 500,
          '%*kcal': 15.15,
        },
        {
          produkt: 'czosnek',
          waga: 10,
          procent: 3,
          'kcal w 100g': 152,
          '%*kcal': 4.61,
        },
        {
          produkt: 'pieprz czarny',
          waga: 5,
          procent: 1.5,
          'kcal w 100g': 375,
          '%*kcal': 5.68,
        },
        {
          produkt: 'sól',
          waga: 5,
          procent: 1.5,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
      ],
    },
    {
      nazwa: 'pesto z rukolą',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '8,-',
      kcal: '602',
      sklad: [
        {
          produkt: 'słonecznik łuskany',
          waga: 240,
          procent: 41,
          'kcal w 100g': 577,
          '%*kcal': 236.72,
        },
        {
          produkt: 'oliwa z oliwek',
          waga: 240,
          procent: 41,
          'kcal w 100g': 882,
          '%*kcal': 361.85,
        },
        {
          produkt: 'rukola',
          waga: 100,
          procent: 17.1,
          'kcal w 100g': 24,
          '%*kcal': 4.1,
        },
        {
          produkt: 'sól',
          waga: 5,
          procent: 0.9,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
      ],
    },
  ],
  littlesomething: [
    {
      nazwa: 'Michałek à la Stacja Zero',
      porcja: '25 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '3,-',
      kcal: '382',
      sklad: [
        {
          produkt: 'masło orzechowe z orzechów ziemnych',
          waga: 100,
          procent: 23.3,
          'kcal w 100g': 610,
          '%*kcal': 141.86,
        },
        {
          produkt: 'napój migdałowy',
          waga: 100,
          procent: 23.3,
          'kcal w 100g': 30,
          '%*kcal': 6.98,
        },
        {
          produkt: 'czekolada ciemna słodzona maltitolem',
          waga: 70,
          procent: 16.3,
          'kcal w 100g': 477,
          '%*kcal': 77.65,
        },
        {
          produkt: 'orzechy ziemne',
          waga: 50,
          procent: 11.6,
          'kcal w 100g': 610,
          '%*kcal': 70.93,
        },
        {
          produkt: 'mleczko kokosowe',
          waga: 40,
          procent: 9.3,
          'kcal w 100g': 229,
          '%*kcal': 21.3,
        },
        {
          produkt: 'cukier kokosowy',
          waga: 30,
          procent: 7,
          'kcal w 100g': 377,
          '%*kcal': 26.3,
        },
        {
          produkt: 'nasiona chia',
          waga: 20,
          procent: 4.7,
          'kcal w 100g': 486,
          '%*kcal': 22.6,
        },
        {
          produkt: 'kakao',
          waga: 20,
          procent: 4.7,
          'kcal w 100g': 310,
          '%*kcal': 14.42,
        },
      ],
    },
    {
      nazwa: 'pączek orkiszowy ze śliwką',
      porcja: '65 g',
      glutenfree: false,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '3,-',
      kcal: '243',
      sklad: [
        {
          produkt: 'mąka orkiszowa typ 2000',
          waga: 16.28,
          procent: 25.2,
          'kcal w 100g': 334,
          '%*kcal': 84.17,
        },
        {
          produkt: 'puree z batatów',
          waga: 11.36,
          procent: 17.6,
          'kcal w 100g': 86,
          '%*kcal': 15.12,
        },
        {
          produkt: 'olej kokosowy',
          waga: 5.88,
          procent: 9.1,
          'kcal w 100g': 900,
          '%*kcal': 81.92,
        },
        {
          produkt: 'napój owsiany',
          waga: 4.52,
          procent: 7,
          'kcal w 100g': 54,
          '%*kcal': 3.78,
        },
        {
          produkt: 'cukier kokosowy',
          waga: 1.12,
          procent: 1.7,
          'kcal w 100g': 377,
          '%*kcal': 6.54,
        },
        {
          produkt: 'drożdże instant',
          waga: 0.32,
          procent: 0.5,
          'kcal w 100g': 325,
          '%*kcal': 1.61,
        },
        {
          produkt: 'sól',
          waga: 0.08,
          procent: 0.1,
          'kcal w 100g': 0,
          '%*kcal': 0,
        },
        {
          produkt: 'wanilia',
          waga: 0.04,
          procent: 0.1,
          'kcal w 100g': 288,
          '%*kcal': 0.18,
        },
        {
          produkt: 'śliwki',
          waga: 20,
          procent: 31,
          'kcal w 100g': 98,
          '%*kcal': 30.34,
        },
        {
          produkt: 'cukier kokosowy',
          waga: 3,
          procent: 4.6,
          'kcal w 100g': 377,
          '%*kcal': 17.51,
        },
        {
          produkt: 'sok z pomarańczy',
          waga: 1,
          procent: 1.5,
          'kcal w 100g': 45,
          '%*kcal': 0.7,
        },
        {
          produkt: 'skórka z pomarańczy',
          waga: 1,
          procent: 1.5,
          'kcal w 100g': 97,
          '%*kcal': 1.5,
        },
      ],
    },
    {
      nazwa: 'kulka kawowa',
      porcja: '20 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '2,-',
      kcal: '326',
      sklad: [
        {
          produkt: 'daktyle',
          waga: 200,
          procent: 62.3,
          'kcal w 100g': 290,
          '%*kcal': 180.69,
        },
        {
          produkt: 'płatki owsiane bezglutenowe',
          waga: 50,
          procent: 15.6,
          'kcal w 100g': 366,
          '%*kcal': 57.01,
        },
        {
          produkt: 'kawa rozpuszczalny',
          waga: 6,
          procent: 1.9,
          'kcal w 100g': 2,
          '%*kcal': 0.04,
        },
        {
          produkt: 'nasiona chia',
          waga: 5,
          procent: 1.6,
          'kcal w 100g': 486,
          '%*kcal': 7.57,
        },
        {
          produkt: 'inulina',
          waga: 10,
          procent: 3.1,
          'kcal w 100g': 210,
          '%*kcal': 6.54,
        },
        {
          produkt: 'czekolada ciemna słodzona maltitolem',
          waga: 50,
          procent: 15.6,
          'kcal w 100g': 477,
          '%*kcal': 74.3,
        },
      ],
    },
  ],
  peanutpaste: [
    {
      nazwa: 'Pasta migdałowa',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '22,-',
      kcal: '605',
      sklad: [
        {
          produkt: 'migdał',
          waga: 200,
          procent: 100,
          'kcal w 100g': 605,
          '%*kcal': 100,
        },
      ],
    },
    {
      nazwa: 'Pasta z pestek dyni',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '16,-',
      kcal: '446',
      sklad: [
        {
          produkt: 'pestki dyni',
          waga: 200,
          procent: 100,
          'kcal w 100g': 446,
          '%*kcal': 100,
        },
      ],
    },
    {
      nazwa: 'Pasta z nerkowców',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '20,-',
      kcal: '553',
      sklad: [
        {
          produkt: 'nerkowiec',
          waga: 200,
          procent: 100,
          'kcal w 100g': 553,
          '%*kcal': 100,
        },
      ],
    },
    {
      nazwa: 'Pasta z orzechów ziemnych',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '12,-',
      kcal: '610',
      sklad: [
        {
          produkt: 'orzechy ziemne',
          waga: 200,
          procent: 100,
          'kcal w 100g': 610,
          '%*kcal': 100,
        },
      ],
    },
    {
      nazwa: 'Pasta z orzechów makadamia',
      porcja: '200 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      cena: '24,-',
      kcal: '718',
      sklad: [
        {
          produkt: 'orzechy makadamia',
          waga: 200,
          procent: 100,
          'kcal w 100g': 718,
          '%*kcal': 100,
        },
      ],
    },
  ],
  inne: [
    {
      nazwa: 'zakwas z buraków',
      cena: '27,-',
      porcja: '0,7 l',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      kcal: '',
    },
    // {
    //   nazwa: 'zakwas z buraków z cebulką',
    //   cena: '27,-',
    //   porcja: '0,7 l',
    //   glutenfree: true,
    //   vegan: true,
    //   lactosefree: true,
    //   sugarfree: true,
    //   kcal: '',
    // },
    // {
    //   nazwa: 'zakwas z buraków',
    //   cena: '27,-',
    //   porcja: '0,7 l', glutenfree: true, vegan: true, lactosefree: true, sugarfree: true,
    //   kcal: '',
    // },
    {
      nazwa: 'zakwas na żur',
      cena: '15,-',
      porcja: '0,48 l',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      kcal: '',
    },
    {
      nazwa: 'zakwas na barszcz',
      cena: '15,-',
      porcja: '0,48 l',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      kcal: '',
    },
    {
      nazwa: 'kimchi',
      cena: '17,-',
      porcja: '300 g',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      kcal: '',
    },
    // {
    //   nazwa: 'kimchi delikatne',
    //   cena: '17,-',
    //   porcja: '300 g',
    //   glutenfree: true,
    //   vegan: true,
    //   lactosefree: true,
    //   sugarfree: true,
    //   kcal: '',
    // },
    // {
    //   nazwa: 'kimchi ostre',
    //   cena: '17,-',
    //   porcja: '300 g',
    //   glutenfree: true,
    //   vegan: true,
    //   lactosefree: true,
    //   sugarfree: true,
    //   kcal: '',
    // },
    {
      nazwa: 'Kombucha',
      cena: '17,-',
      porcja: '0,5 l',
      glutenfree: true,
      vegan: true,
      lactosefree: true,
      sugarfree: true,
      kcal: '',
    },
    // {
    //   nazwa: 'Kombucha imbirowa',
    //   cena: '17,-',
    //   porcja: '0,5 l',
    //   glutenfree: true,
    //   vegan: true,
    //   lactosefree: true,
    //   sugarfree: true,
    //   kcal: '',
    // },
    // {
    //   nazwa: 'Kombucha truskawkowa',
    //   cena: '17,-',
    //   porcja: '0,5 l',
    //   glutenfree: true,
    //   vegan: true,
    //   lactosefree: true,
    //   sugarfree: true,
    //   kcal: '',
    // },
    // {
    //   nazwa: 'olej z czarnuszki',
    //   cena: '55,-',
    //   porcja: '250 ml', glutenfree: true, vegan: true, lactosefree: true, sugarfree: true,
    //   kcal: '',
    // },
    // {
    //   nazwa: 'olej rzepakowy',
    //   cena: '11,-',
    //   porcja: '250 ml', glutenfree: true, vegan: true, lactosefree: true, sugarfree: true,
    //   kcal: '',
    // },
    // {
    //   nazwa: 'olej z ostropestu',
    //   cena: '34,-',
    //   porcja: '250 ml', glutenfree: true, vegan: true, lactosefree: true, sugarfree: true,
    //   kcal: '',
    // },
    // {
    //   nazwa: 'olej konopny',
    //   cena: '34,-',
    //   porcja: '250 ml', glutenfree: true, vegan: true, lactosefree: true, sugarfree: true,
    //   kcal: '',
    // },
    // {
    //   nazwa: 'olej lniany',
    //   cena: '16,-',
    //   porcja: '250 ml', glutenfree: true, vegan: true, lactosefree: true, sugarfree: true,
    //   kcal: '',
    // },
  ],
  napoje: [
    {
      nazwa: 'Ciepłe napoje',
      sklad: [
        {
          rodzaj: 'espresso ',
          cena: '6,- ',
        },
        {
          rodzaj: 'espresso doppio ',
          cena: '8,- ',
        },
        {
          rodzaj: 'americano ',
          cena: '8,- ',
        },
        {
          rodzaj: 'cappuccino ',
          cena: '8,- ',
        },
        {
          rodzaj: 'flat white ',
          cena: '9,- ',
        },
        {
          rodzaj: 'latte ',
          cena: '10,- ',
        },
        {
          rodzaj: 'herbata ',
          cena: '7,- ',
        },
        {
          rodzaj: 'herbata rozgrzewająca ',
          cena: '12,- ',
        },
        {
          rodzaj: 'mleko bez laktozy/napój roślinny',
          cena: '+2,- ',
        },
      ],
    },
    {
      nazwa: 'Soki jednosmakowe',
      sklad: [
        {
          rodzaj: 'jabłko',
          small: '6,-',
          large: '10,-',
        },
        {
          rodzaj: 'marchew',
          small: '6,-',
          large: '10,-',
        },
        {
          rodzaj: 'burak',
          small: '6,-',
          large: '10,-',
        },
        {
          rodzaj: 'gruszka',
          small: '6,-',
          large: '10,-',
        },
        {
          rodzaj: 'pomidor',
          small: '8,-',
          large: '12,-',
        },
        {
          rodzaj: 'pomarańcza',
          small: '8,-',
          large: '12,-',
        },
        {
          rodzaj: 'grejpfrut',
          small: '8,-',
          large: '12,-',
        },
      ],
    },
    {
      nazwa: 'Autorskie miksy',
      sklad: [
        {
          rodzaj: 'kapusta/jabłko/gruszka/natka pietruszki',
          small: '8,-',
          large: '12,-',
        },
        {
          rodzaj: 'burak/marchew/jabłko/cytryna',
          small: '8,-',
          large: '12,-',
        },
        {
          rodzaj: 'pomidor/kapusta/seler naciowy/jarmuż',
          small: '8,-',
          large: '12,-',
        },
        {
          rodzaj: 'ananas/marchew/jabłko/imbir',
          small: '10,-',
          large: '14,-',
        },
        {
          rodzaj: 'gruszka/ogórek/winogrono/szpinak',
          small: '10,-',
          large: '14,-',
        },
        {
          rodzaj: 'papryka/marchew/pomarańcza',
          small: '10,-',
          large: '14,-',
        },
      ],
    },
    {
      nazwa: 'Szoty witaminowe',
      sklad: [
        {
          rodzaj: 'imbir/kurkuma/miód/cytryna',
          cena: '5,-',
        },
        {
          rodzaj: 'natka pietruszki/młody jęczmień/miód/cytryna',
          cena: '5,-',
        },
        {
          rodzaj: 'burak/mięta/miód/cytryna',
          cena: '5,-',
        },
      ],
    },
    {
      nazwa: 'dodatki',
      sklad: [
        {
          rodzaj: 'imbir',
          cena: '1,-',
        },
        {
          rodzaj: 'natka pietruszki',
          cena: '1,-',
        },
        {
          rodzaj: 'cytryna',
          cena: '1,-',
        },
        {
          rodzaj: 'mięta',
          cena: '1,-',
        },
        {
          rodzaj: 'szpinak',
          cena: '2,-',
        },
        {
          rodzaj: 'jarmuż',
          cena: '2,-',
        },
        {
          rodzaj: 'seler naciowy',
          cena: '2,-',
        },
        {
          rodzaj: 'winogrono',
          cena: '2,-',
        },
      ],
    },
  ],
};
